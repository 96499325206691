<template>
  <div id="wrapper" class="l-wrapper--login">
    <!-- HEADER -->
    <header id="header" class="l-header--login">
      <div class="l-header__inner">
        <a :href="`${customerRootUrl}/sign_in`" class="l-header__logo--login">
          <img src="/img/logo.svg" alt="株式会社デンソーソリューション" width="" height="">
        </a>
      </div>
    </header>
    <!-- /HEADER -->
    <!-- MAIN -->
    <main id="main" class="l-main--login">
      <div class="p-login p-login-customer">
        <div class="p-login__inner">
          <p class="p-login__intro"><span>DS BOX（ディーズボックス）</span>はデンソーソリューションのWebカタログです。<br> ログインを希望されるお客様は営業スタッフまでお問合せください。</p>
          <h2 class="p-login__ttl">ログイン</h2>
          <div class="p-login__body">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form ref="form" @submit.prevent="handleSubmit(onSubmit)" :action="getAction()" method="post">
                <input type="hidden" name="authenticity_token" :value="csrfToken">
                <div class="p-login__row">
                  <ValidationProvider name="ID" :rules="draft ? '' : 'required|email'" v-slot="{ errors }">
                    <input type="text" class="form-control" value="" size="" name="customer[email]" placeholder="ID" v-model="customerData.email">
                    <span class="error-explanation">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="p-login__row">
                  <ValidationProvider name="パスワード" :rules="draft ? '' : 'required'" v-slot="{ errors }">
                    <input type="password" class="form-control" value="" size="" name="customer[password]" placeholder="パスワード" v-model="customerData.password">
                    <span class="error-explanation">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <p class="p-login__check">
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="customerData.remember_me"
                      name="customer[remember_me]"
                      value=1
                      unchecked-value=0
                    >
                      次回から自動ログインする
                    </b-form-checkbox>
                  </p>
                  <p class="p-login__check">
                    <a :href="`${customerRootUrl}/term_of_service`">利用規約</a>に同意します
                  </p>
                </div>
                <div class="p-login__btn">
                  <input type="submit" name="" class="c-button c-button--login" value="ログイン">
                </div>
              </form>
            </ValidationObserver>
          </div>
          <ul class="c-nav-password">
            <li class="c-nav-password__item text-danger">※「Internet Explorer」には対応しておりません。ブラウザは「Microsoft Edge」、</li>
            <li class="c-nav-password__item text-danger">「Google Chrome」、「Safari」の各最新版のご使用を推奨致します。</li>
            <li class="c-nav-password__item"><a :href="`${customerRootUrl}/password/new`">パスワードを忘れた方、パスワード変更はこちら</a></li>
          </ul>
        </div>
      </div>
    </main>
    <!-- /MAIN -->
    <!-- FOOTER -->
    <footer id="footer" class="l-footer--login">
      <!-- <p class="l-footer--login__link"><a href="">管理者に問い合わせる</a></p> -->
    </footer><!-- /FOOTER -->
  </div>
</template>

<script>
import Util from '@/utils/util.js';

export default {
  data() {
    return {
      customerRootUrl: process.env.VUE_APP_CUSTOMER_URL,
      csrfToken: Util.getCsrfToken(),
      customerData: {
        email: null,
        password: null,
        remember_me: 0
      }
    };
  },

  methods: {
    onSubmit() {
      this.$refs.form.submit();
    },
    getAction() {
      return `${this.customerRootUrl}/sign_in`;
    }
  }
};
</script>

<style lang="scss" scoped>
  .c-nav-password__item {
    a {
      text-decoration: none;
    }
  }

  .p-login__ttl {
    font-weight: bold;
  }

  .p-login__row {
    input::placeholder {
      font-family:
      -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",
      Arial,"Noto Sans",
      sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    }
  }

  .error-explanation {
    font-size: 0.8rem;
  }

  .p-login__intro {
    font-size: 15px;
    font-size: .9375rem;
    text-align: center;
    letter-spacing: .05rem;
    text-indent: .05rem;
    padding: 25px 5%;
    margin: 0 auto 45px;
    background: #f7f7f7
  }
  .p-login__intro span {
    font-weight: 700
  }

  .l-main--login {
    text-align: center;
  }

</style>