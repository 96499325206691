export const NewsScope = [
  { name: '内部ニュース', value: 'internal' },
  { name: '通常のニュース', value: 'public' }
];

export const BannerPosition = [
  { name: 'メイン', value: 'main' },
  { name: 'おすすめ', value: 'recommend' },
  { name: 'おすすめの下', value: 'under_recommend' }
];

export const VisibilitiesTableOptions = [
  { name: '表示', value: 'display' },
  { name: '非表示', value: 'hidden' }
];

export const showInventoryOptions = [
  { name: 'する', value: 'true' },
  { name: 'しない', value: 'false' }
];

export const limitInventoryOptions = [
  { name: 'ON', value: 'true' },
  { name: 'OFF', value: 'false' }
];

export const UserRole = [
  { value: 'super_admin', text: '全社管理者' },
  { value: 'ads_moderator', text: '広告宣伝部署' },
  { value: 'branch_admin', text: '支社管理者' },
  { value: 'branch_seller', text: '担当者' }
];

export const ServingOption = [
  { name: 'メイン', value: 'main' },
  { name: 'サブ', value: 'sub' }
];

export const NewsCategorys = [
  { value: 'annoucement', text: 'お知らせ' },
  { value: 'event', text: 'イベント' },
  { value: 'new_product', text: '新規商品' }
];

export const DocumentType = [
  { text: 'リンク', value: 'link' },
  { text: 'ファイル', value: 'file' }
];

export const ProductRelationShip = [
  { name: 'メーカーで表示する', value: 'manufacturer' },
  { name: 'カテゴリーで表示する', value: 'category' },
  { name: '関連商品コードで表示する', value: 'recommendation_code' },
  { name: '手動で設定する', value: 'product' }
];

export const ProductPricings = [
  { name: 'SS/N（一次店価格）（税別）', value: 'price_ssn' },
  { name: 'TMPk/N（税別）', value: 'price_tmpk' },
  { name: 'SSS/N（二次店価格）（税別）', value: 'price_sssn' },
  { name: 'ディーラー/Ｎ', value: 'price_other_1' },
  { name: 'その他価格（税別）', value: 'price_other_2' }
];

export const CustomerModes = [
  { name: '担当の得意先', value: 1 },
  { name: '所属支社の得意先', value: 2 },
  { name: '全支社', value: 3 }
];

export const ChecklistOption = [
  { text: '〇', value: true },
  { text: 'ー', value: false }
];