
import { API } from '../api';
import { API_INTERNAL } from '../apiInternal';

export const state = {

};

export const mutations = {

};

export const getters = {

};

export const actions = {
  async searchNews(context, query) {
    try {
      return await API.get('/news/search', query);
    } catch (error) {
      return error.response.data;
    }
  },

  async searchNewsInternal(context, query) {
    try {
      return await API_INTERNAL.get('/news/search', query);
    } catch (error) {
      return error.response.data;
    }
  },

  async customerAccessNews(context, query) {
    try {
      return await API.get(`/news/${query.id}/clicked`);
    } catch (error) {
      return error.response.data;
    }
  },

  async userAccessNews(context, query) {
    try {
      return await API_INTERNAL.get(`/news/${query.id}/clicked`);
    } catch (error) {
      return error.response.data;
    }
  }
};