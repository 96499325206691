<template>
  <div>
    <common-header :internal="internal" :maintenance="maintenance" :current_user_root="current_user_root"></common-header>
    <common-search-box :internal="internal"></common-search-box>
    <!-- MAIN -->
    <main id="main" class="l-main">
      <!-- BREADCRUMB -->
      <div class="c-breadcrumb">
        <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="c-breadcrumb__list c-grid">
          <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="c-breadcrumb__item">
            <a itemprop="item" :href="`${customerUrl}`| customerUrl()" class="c-breadcrumb__link" data-turbolinks="false">
              <span itemprop="name"><i class="ico ico-home"></i>トップ</span>
            </a>
            <meta itemprop="position" content="1">
          </li>
          <li v-if="main_category" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="c-breadcrumb__item">
            <a
              itemprop="item"
              :href="`${customerUrl}/products?main_category_id=${main_category.id}` | customerUrl()"
              data-turbolinks="false"
            >
              <span itemprop="name">{{ main_category.name }}</span>
            </a>
            <meta itemprop="position" content="2">
          </li>
          <li v-if="sub_category && main_category" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="c-breadcrumb__item">
            <a
              itemprop="item"
              :href="`${customerUrl}/products?sub_category_id=${sub_category.id}` | customerUrl()"
              data-turbolinks="false"
            >
              <span itemprop="name">{{ sub_category.name }}</span>
            </a>
            <meta itemprop="position" content="2">
          </li>
          <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="c-breadcrumb__item">
            <a itemprop="item" href="" data-turbolinks="false">
              <span itemprop="name">{{product.name}}</span>
            </a>
            <meta itemprop="position" content="4">
          </li>
        </ol>
      </div><!-- /BREADCRUMB -->
      <!-- HEADLINE -->
      <div class="c-headline-product">
        <div class="c-headline-product__inner c-grid">
          <p class="c-headline-product__meta" v-if="product.note_top"><i class="c-icon c-icon--att"></i>{{product.note_top}}</p>
          <div class="c-headline-product__body">
            <h1 class="c-headline-product__ttl">{{product.name}}</h1>
            <p class="c-headline-product__dsc">{{product.product_model}}</p>
          </div>
        </div>
      </div><!-- /HEADLINE -->
      <!-- PRODUCT-POST -->
      <div class="p-product-post c-grid">
        <!-- PRODUCT-POST > CONTENT -->
        <article class="p-product-post__content">
          <div class="p-product-post__head">
            <div class="swiper-container p-gallery">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(image, index) in images" :key="index">
                  <div class="product-image" :style="{ backgroundImage: 'url(' + image + ')'}"></div>
                </div>
              </div>
              <div class="btn-gallery--prev"></div>
              <div class="btn-gallery--next"></div>
            </div>
            <div class="swiper-container p-gallery-thumbs">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(image, index) in images" :key="index">
                  <img :src="image" width="1500" height="900">
                </div>
              </div>
            </div>
            <div class="p-product-post__dsc">
              {{product.promotion}}
            </div>
          </div>
          <div class="p-point">
            <h3 class="p-point__ttl">おすすめポイント</h3>
            <ol class="p-point__list">
              <li class="p-point__item" v-if="product.recommended_point_1"><i>1</i>{{product.recommended_point_1}}</li>
              <li class="p-point__item" v-if="product.recommended_point_2"><i>2</i>{{product.recommended_point_2}}</li>
              <li class="p-point__item" v-if="product.recommended_point_3"><i>3</i>{{product.recommended_point_3}}</li>
            </ol>
            <p class="p-point__dsc" v-if="product.recommended_point_other">{{product.recommended_point_other}}</p>
          </div>
          <div class="p-manual">
            <table class="p-manual__list">
              <tr v-for="(spec, index) in specs" :key="index">
                <th class="spec-name">{{spec.name}}</th>
                <td><div v-html="spec.value"></div></td>
              </tr>
            </table>
            <p class="p-manual__note output-textarea">{{product.note_bottom}}</p>
            <div class="d-flex" v-if="product.note_bottom_red">
              <p class="p-manual__caution"><i class="c-icon c-icon--att"></i></p>
              <p class="p-manual__caution output-textarea">
                <span><strong>{{product.note_bottom_red}}</strong></span>
              </p>
            </div>
          </div>
        </article><!-- /PRODUCT-POST > CONTENT -->
        <!-- PRODUCT-POST > SIDE -->
        <aside class="p-product-post__side">
          <div class="c-sidenav">
            <ul class="c-sidenav__list c-sidenav__list--primary" v-if="documents.filter(_ => _.customer_shown).length">
              <li v-for="(document, index) in documents.filter(_ => _.customer_shown)" :key="index" class="c-sidenav__item">
                <a v-if="document.doc_type == 'link'"  target="_blank" :href="document.url">{{document.title}}</a>
                <a v-if="document.doc_type == 'file'"  download :href="document.file_url">{{document.title}}</a>
              </li>
            </ul>
            <ul class="c-sidenav__list c-sidenav__list--secondary" v-if="documents.filter(_ => !_.customer_shown).length && internal">
              <li v-for="(document, index) in documents.filter(_ => !_.customer_shown)" :key="index" class="c-sidenav__item">
                <a v-if="document.doc_type == 'link'"  target="_blank" :href="document.url">{{document.title}}</a>
                <a v-if="document.doc_type == 'file'"  download :href="document.file_url">{{document.title}}</a>
              </li>
            </ul>
            <ul class="c-sidenav__list c-sidenav__list--tertiary" v-if="manager">
              <li class="c-sidenav__item">
                <a :href="`mailto:${manager.email ? manager.email : ''}?subject=「${product.name}」に関するお問い合わせ&body=${encodedBody()}` "><i class="c-icon c-icon--mail"></i>お問い合わせ</a>
              </li>
            </ul>
              <a herf v-show="!this.liked" class="c-sidenav__btn" @click="like" v-if="!viewAsCustomer">
                <i class="c-icon c-icon--addto"></i>
                <span>お気に入りに追加する</span>
              </a>
              <a herf v-show="this.liked" class="c-sidenav__btn" @click="unlike" v-if="!viewAsCustomer">
                <i class="c-icon c-icon--favorite_like--product"></i>
                <span>お気に入りから削除する</span>
              </a>
          </div>
          <div class="c-price">
            <ul class="c-price__list">
              <li class="c-price__item size-bigger-2px" v-if="priceBranch('price_lp')">メーカー希望小売価格（税込）<br v-if="priceBranchValue() !== ''"/><span class="c-price__meta c-price__meta--open size-bigger-2px">{{priceBranchValue()}}円</span></li>
              <li class="c-price__item size-bigger-2px" v-else>メーカー希望小売価格（税込）<br v-if="priceBranch('price_reference_text') !== ''"/><span class="c-price__meta c-price__meta--yen size-bigger-2px">{{priceBranch('price_reference_text')}}</span></li>
            </ul>
            <p class="c-price__note" v-if="product.price_note"><span>{{product.price_note}}</span></p>

             <p class="c-button c-button--price" v-if="internal">
              <a  href="#js-modal-price" class="popup-modal">ルート毎の価格を確認</a>
            </p>
          </div>
          <div class="c-price" v-if="!(!this.internal && !product.show_public_inventory || !this.internal && !product.show_inventory)">
            <p class="c-price__item">前営業日までの在庫情報</p>
            <p class="c-price__item inventory" v-html="inventory(product)"></p>
            <p class="c-price__item">{{product.disco_model}} ※在庫情報要確認</p>
          </div>
          <div class="c-price a-tag" v-if="!internal && product_variants.length">
            <p>{{product.variant_code}}</p>
            <a class="badge badge-light product-variants mr-2 mt-2 origin-variant" v-if="product.variant_name">{{product.variant_name}}</a>
            <a v-for="(item, index) in product_variants" :key="index" :href="customerUrl + '/products/' + item.id  | customerUrl(view_as)" data-turbolinks="false" class="badge badge-light product-variants mr-2 mt-2">{{item.variant_name}}</a>
          </div>
          <div class="c-price a-tag" v-if="internal && product_variants.length">
            <p>{{product.variant_code}}</p>
            <button class="show-cart-btn" @click="checkProduct()">一括選択</button><br/>
            <input type="checkbox" class="variant-checkbox"
              :checked="isProductChecked(product)"
              @click="checkProduct(product)"
              >
            <a class="badge badge-light product-variants mr-2 mt-2 origin-variant" v-if="product.variant_name">{{product.variant_name}}</a>
            <div v-for="(item, index) in product_variants">
              <input type="checkbox" class="variant-checkbox"
                @change="checkProduct(item)"
                :checked="isProductChecked(item)"
                >
              <a :key="index" :href="customerUrl + '/products/' + item.id  | customerUrl(view_as)" data-turbolinks="false" class="badge badge-light product-variants mr-2 mt-2">{{item.variant_name}}</a>
            </div>
          </div>
          <!-- <div class="c-sidenav margin-22" v-if="internal">
            <ul class="export-class">
              <li class="c-sidenav__item">
                <form class=""
                  :action="(value == 'suggestion_letter_all' || value == 'estimation_all') ? '/internal/products/export_preview' : '/internal/products/export'"
                  v-for="(key, value) in export_modes"
                  v-if="isExportAll(value)"
                  :key="key"
                >
                  <input type="hidden" name="mode" :value="value"/>
                  <input type="hidden" name="id" :value="product.id"/>
                  <button type="submit" class="btn-export-excel">{{key}}</button>
                </form>
              </li>
            </ul>
          </div> -->
        </aside><!-- /PRODUCT-POST > SIDE -->
      </div><!-- /PRODUCT-POST -->
      <div class="p-relation" v-if="product_relationships.length">
        <div class="p-relation__inner c-grid">
          <div class="p-recommend__row">
            <h2 class="c-title c-title--relation"><span>関連商品</span></h2>
            <ul class="p-relation__list">
              <li class="p-relation__item" v-for="(product, index) in product_relationships" :key="index">
                <a :href="customerUrl + '/products/' + product.id  | customerUrl(view_as)" data-turbolinks="false">
                  <div class="c-flame">
                    <img :src="product.main_image_url" alt="" width="330" height="330" v-if="product.main_image_url">
                    <img src="/img/no-image.png" alt="" width="330" height="330" v-else>
                  </div>
                  <h3 class="p-relation__dsc">{{product.name}}</h3>
                  <p class="p-relation__num">{{product.product_model}}</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main><!-- /MAIN -->
    <common-info-manager></common-info-manager>
    <common-footer :internal="internal"></common-footer>
    <modal-product-pricing v-if="internal"
      :branches="branches"
      :pricings="pricings"
      :branch_id="branch_id"
      :role="role"
      :product="product"
    ></modal-product-pricing>
    <cart v-if="internal"
      :key="cartKey"
      :products="this.products"
      :selected_products="this.selectedProducts"
      :from_show_page="true"
      :is_show_cart="this.isShowCart"
      :current_user_root="current_user_root"
    ></cart>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Util from '@/utils/util.js';

export default {
  props: [
    'product',
    'images',
    'specs',
    'documents',
    'liked',
    'product_relationships',
    'internal',
    'branches',
    'pricings',
    'branch_id',
    'role',
    'route_id',
    'product_variants',
    'sub_category',
    'main_category',
    'current_user',
    'maintenance',
    'current_user_root'
  ],
  data() {
    return {
      customerUrl: this.internal ? process.env.VUE_APP_INTERNAL_URL : process.env.VUE_APP_CUSTOMER_URL,
      currentUrl: null,
      viewAsCustomer: Util.viewAsCustomer(),
      csrfToken: Util.getCsrfToken(),
      export_modes: {
        suggestion_letter: '提案レター作成',
        estimation: '見積書作成',
        suggestion_letter_all: '提案レター一括作成',
        estimation_all: '見積書一括作成'
      },
      selectedProducts: this.loadSelectedProduct(),
      isShowCart: false,
      cartKey: "cart_key",
    };
  },
  beforeMount() {
    const plugin = document.createElement('script');
    plugin.setAttribute(
      'src',
      '/js/index.bundle.js'
    );
    plugin.async = true;
    document.body.appendChild(plugin);
  },
  created() {
    this.currentUrl = `${window.location.host}${window.location.pathname}`;
    this.product.checked = true
  },
  computed: {
    ...mapState('user', {
      manager: state => state.manager
    })
  },

  methods: {
    ...mapActions('product', ['likeProduct', 'unLikeProduct']),

    async like() {
      this.liked = true;
      return await this.likeProduct({ ...this.product, internal: this.internal });
    },

    async unlike() {
      this.liked = false;
      return await this.unLikeProduct({ ...this.product, internal: this.internal });
    },

    priceBranch(priceName) {
      return this.product[priceName];
    },

    priceBranchValue() {
      if (this.product.consumption_tax_classification) {
        return Math.round(parseFloat(this.product.price_lp) + parseFloat(this.product.price_lp * this.product.consumption_tax_classification / 100));
      }
      return Math.round(parseFloat(this.product.price_lp));
    },

    encodedBody() {
      return encodeURIComponent(`( ) 商品機能
( ) お見積り
( ) 納期
商品ページ： ${this.currentUrl ? ' https://' + this.currentUrl : ''} 
<デンソーソリューション社員の方へ>
上記リンクを閲覧する際は必ず社員用DS BOXサイトにログインしてください。
■お問合せ内容記載欄
＜お客様情報＞
Eメール：${this.current_user.email || ''}
会社名：${this.current_user.company_name || ''}
氏名： ${this.current_user.name || ''}`
      );
    },

    isExportAll(value){
      if (this.product_variants.length <= 0 && (value == 'suggestion_letter_all' || value == 'estimation_all')) { return false; }

      return true;
    },

    inventory(product) {
      let icon = '－';
      let text = '表示対象外';
      let iconText = '<span style="font-size: 1.3rem;height: 25px;position: relative;top: 3px;">';
      let spanClass = '<span style="color:#4c4948;">在庫情報：';

      if (!product.show_inventory || product.is_limit_inventory) {
        return `${spanClass} </span>${iconText}${icon}</span>${text}`;
      };

      if (product.inventory > 0) {
        icon = '〇';
        text = '在庫有り(担当にご確認ください)​';
        return `${spanClass} </span>${iconText}${icon}</span>${text}`;
      };

      return `${spanClass} </span>${iconText}×</span>在庫なし(担当にご確認ください)`;
    },

    checkVariant() {
      this.isShowCart = true
    },

    showCart() {
      this.isShowCart = !this.isShowCart
    },

    checkProduct(product) {
      if (!this.internal) {return};
      if (!product) {
        let currentProducts = [this.product];
        currentProducts.push(...this.product_variants);
        var unSelected = currentProducts.filter((e)=> !this.selectedProducts.map(e => e.id).includes(e.id));
        if (unSelected.length < 1) {return;}

        this.selectedProducts.push(...unSelected)
        this.selectedProducts.map(e => e.checked = true)
        this.isShowCart = true
        sessionStorage.setItem(this.current_user_root.email, JSON.stringify(this.selectedProducts.map(product => product)));
        this.cartKey = "checkAll";
        return;
      }
      var productSelected = this.product_variants.find( (e) => e.id == product.id);
      if (product === this.product) {
        productSelected = this.product;
      }
      if (productSelected.checked) {
        this.removeProduct(productSelected);
        return;
      }
      this.selectedProducts.push(productSelected);
      productSelected.checked = true
      this.isShowCart = true
      sessionStorage.setItem(this.current_user_root.email, JSON.stringify(this.selectedProducts.map(product => product)));
      this.cartKey = "checkProduct";
    },

    removeProduct(product) {
      if (!product) {
        this.selectedProducts = [];
        this.product_variants.map((product) => product.checked = false)
        this.product.checked = false
        this.isShowCart = false
        sessionStorage.removeItem(this.current_user_root.email);
        this.cartKey = "removeAll";
        return;
      }
      var productSelected = this.selectedProducts.find( (e) => e.id == product.id);
      if (!productSelected) {return;}
      var index = this.selectedProducts.indexOf(productSelected);
      this.selectedProducts.splice(index, 1);
      productSelected.checked = false;
      product.checked = false;
      this.isShowCart = true
      sessionStorage.setItem(this.current_user_root.email, JSON.stringify(this.selectedProducts));
      this.cartKey = "removeProduct";
    },

    isProductChecked(product){
      if (this.$refs.reference) {
        var element = this.$refs.reference.find( (e) => e.id == product.id );
        if (element) {
          element.checked = product.checked
        }
      }
      if (this.selectedProducts.length) {
        var productSelected = this.selectedProducts.find( (e) => e.id == product.id )
        if (productSelected) {
          return productSelected.checked
        }
      }

      return product.checked
    },

    loadSelectedProduct() {
      if (!this.internal) {return;}

      this.product.checked = true
      let selected = JSON.parse(sessionStorage.getItem(this.current_user_root.email)) || [];
      let selectedIds = selected.map(object => object.id);
      if (!selectedIds.includes(this.product.id)) {
        selected.push(this.product)
        sessionStorage.setItem(this.current_user_root.email, JSON.stringify(selected));
      }
      let currentProducts = [this.product];
      currentProducts.push(...this.product_variants);
      currentProducts.filter(e => selectedIds.includes(e.id)).map( e => e.checked = true );

      return selected
    }
  }
};
</script>

<style lang="scss" scoped>
.spec-name {
  width: 270px;
}
.c-icon--favorite_like--product{
  display: inline-block;
  width: 37px;
  height: 37px;
  margin: 0 10px 0 0;
  z-index: 10
}
.c-icon--favorite_like--product:before {
  content: "";
  display: inline-block;
  width: 37px;
  height: 37px;
  vertical-align: middle;
  background: url(/img/ico-favorite.svg) no-repeat center center/cover
}

.product-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 450px;
}

.product-variants {
  padding: 8px 15px;
  border: thin solid #d4d4d4;
}

.origin-variant {
  pointer-events: none;
  background: antiquewhite;
}

::v-deep {
  .raw-html-embed {
    p {
      display: flex;
    }
    a {
      img {
        width: 100%;
        max-width: 100%!important;
        border: none!important;
      }
    }
  }
}

.c-price__meta {
  font-weight: normal;
  font-size: inherit;
}

.output-textarea {
  white-space: -moz-pre-line;  /* Mozilla, since 1999 */
  white-space: -webkit-pre-line;          /* Chrome & Safari */
  white-space: -o-pre-line;               /* Opera 7 */
  white-space: pre-line;                  /* CSS3 */
  word-wrap: break-word;                  /* Internet Explorer 5.5+ */
  word-break: break-all;
}
.p-point__item {
  white-space: pre-line;
}

.inventory {
  color: red;
}
.variant-checkbox {
  transform: scale(1.3);;
  position: relative;
  top: 2px;
  margin-right: 2px
}

.show-cart-btn {
  color: #fff;
  border-color: #8c8c8c;
  background: #8c8c8c;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  letter-spacing: .06em;
  text-indent: 0.06rem;
  padding: 5px 0;
  border: 1px solid #828282;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
}

.a-tag a {
  max-width: 76%;
  white-space: normal;
}

.size-bigger-2px {
  font-size: 16px !important;
}
</style>