
import { API } from '../api';

export const state = {
  manager: null,
  currentUser: null
};

export const mutations = {
  SET_MANAGER(state, manager) {
    state.manager = _.cloneDeep(manager);
  },

  SET_CURRENT_USER(state, user) {
    state.currentUser = _.cloneDeep(user);
  }
};

export const getters = {

};

export const actions = {
  async getManager(context, query = {}) {
    let manager = null;
    try {
      const res = await API.get('/profiles/manager', query);
      if (res && res.data) {
        manager = res.data;
      }
    } catch (error) {
      return error.response.data;
    }
    context.commit('SET_MANAGER', manager);
  },

  async getCurrentUser(context, query = {}) {
    let user = null;
    try {
      const res = await API.get('/profiles/me', query);
      if (res && res.data) {
        user = res.data;
      }
    } catch (error) {
      return error.response.data;
    }
    context.commit('SET_CURRENT_USER', user);
  }
};