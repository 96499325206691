<template>
  <!-- TOPICS -->
  <section class="p-topics-dsjp c-grid">
    <div class="p-topics-dsjp__inner">
      <h2 class="p-topics-dsjp__ttl">DSJP社員向け<span>ニュース</span></h2>
      <div class="p-topics-dsjp__body">
        <ul class="p-topics-dsjp__list">
          <li class="p-topics-dsjp__item" v-for="(news, index) in list_news_internal" :key="index">
            <a @click="openNewsDetail(news)">
              <time class="p-topics-dsjp__time" datetime="2020-01-15">{{ news.posted_at | formatDate('YYYY年MM月DD日') }}</time>
              <div class="p-topics-dsjp__post">
                <ul class="c-meta-cat__list">
                  <li class="c-meta-cat__item"><span>{{ news.category_name }}</span></li>
                </ul>
                <h3 class="p-topics-dsjp__dsc">{{ news.title }}</h3>
              </div>
            </a>
          </li>
        </ul>
        <p class="p-topics__link"><a :href="`${customerRootUrl}/news?internal=true`| customerUrl()" data-turbolinks="false">もっと見る</a></p>
      </div>
    </div>
  </section><!-- /TOPICS -->
</template>

<script>
export default {
  props: ['list_news_internal'],
  data() {
    return {
      customerRootUrl: process.env.VUE_APP_INTERNAL_URL
    };
  },
  methods: {
    openNewsDetail(news) {
      if (news.url) {
        window.location.href = news.url;
      } else {
        window.location.href = `${this.customerRootUrl}/news/${news.id}?internal=true`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
 .p-topics-dsjp__ttl {
   font-weight: bold;
 }
</style>