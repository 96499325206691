<template>
  <div>
    <common-header :internal="internal" :maintenance="maintenance" :current_user_root="current_user_root"></common-header>
    <common-search-box :internal="internal"></common-search-box>
    <!-- MAIN -->
    <main id="main" class="l-main">
      <!-- BREADCRUMB -->
      <div class="c-breadcrumb">
        <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="c-breadcrumb__list c-grid">
          <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="c-breadcrumb__item">
            <a itemprop="item" :href="`${customerUrl}`| customerUrl()" class="c-breadcrumb__link" data-turbolinks="false">
              <span itemprop="name"><i class="ico ico-home"></i>トップ</span>
            </a>
            <meta itemprop="position" content="1">
          </li>
          <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="c-breadcrumb__item">
            <a itemprop="item" href="" data-turbolinks="false">
              <span itemprop="name">特集一覧</span>
            </a>
            <meta itemprop="position" content="2">
          </li>
        </ol>
      </div><!-- /BREADCRUMB -->
      <!-- HEADLINE -->
      <div class="c-headline">
        <div class="c-headline__inner c-grid">
          <div class="c-headline__body">
            <h2 class="c-headline__ttl">特集一覧</h2>
          </div>
        </div>
      </div><!-- /HEADLINE -->
      <!-- SPECIAL -->
      <section class="p-special">
        <div class="p-special__inner c-grid">
          <div class="p-special__body">
            <div class="p-special__row">
              <h2 class="c-title c-title--special">メイン特集</h2>
              <ul class="p-special__list p-special__list--primary">
                <li class="p-special__item" v-for="(banner, index) in banners.filter(_=>_.position == 'main')" :key="index" @click="clickedBanner(banner.id)" @auxclick="clickedBanner(banner.id)">
                  <a :href="replaceUrl(banner.url)">
                    <img :src="banner.image_url" :alt="banner.title" width="1800" height="800" class="banner-image_main">
                    <h3 class="p-special__ttl">{{banner.title}}</h3>
                  </a>
                </li>
              </ul>
            </div>
            <div class="p-special__row">
              <h2 class="c-title c-title--special">おすすめ特集</h2>
              <ul class="p-special__list p-special__list--secondary">
                <li class="p-special__item" v-for="(banner, index) in banners.filter(_=>_.position == 'recommend')" :key="index" @click="clickedBanner(banner.id)" @auxclick="clickedBanner(banner.id)">
                  <a :href="replaceUrl(banner.url)">
                    <img :src="banner.image_url" :alt="banner.title" width="312" height="312" class="banner-image_recommend">
                    <h3 class="p-special__ttl">{{banner.title}}</h3>
                  </a>
                </li>
              </ul>
              <hr class="c-hr">
            </div>
            <div class="p-special__row">
              <ul class="p-special__list p-special__list--tertiary">
                <li class="p-special__item" v-for="(banner, index) in banners.filter(_=>_.position == 'under_recommend')" :key="index" @click="clickedBanner(banner.id)" @auxclick="clickedBanner(banner.id)">
                  <a :href="replaceUrl(banner.url)">
                    <img :src="banner.image_url" :alt="banner.title" width="700" height="240" class="banner-image_under-recommend">
                    <h3 class="p-special__ttl">{{banner.title}}</h3>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section><!-- /SPECIAL-ARCHIVE -->
    </main><!-- /MAIN -->
    <common-info-manager></common-info-manager>
    <common-footer :internal="internal"></common-footer>
  </div>
</template>

<script>
import Util from '@/utils/util.js';
import { mapActions } from 'vuex';

export default {
  props: ['banners', 'internal', 'maintenance', 'current_user_root'],
  data() {
    return {
      customerUrl: this.internal ? process.env.VUE_APP_INTERNAL_URL : process.env.VUE_APP_CUSTOMER_URL,
      userIsView: false,
      customerId: Util.viewAsCustomer()
    };
  },

  beforeMount() {
    const plugin = document.createElement('script');
    plugin.setAttribute(
      'src',
      '/js/index.bundle.js'
    );
    plugin.async = true;
    document.body.appendChild(plugin);
  },

  methods: {
    ...mapActions('banner', ['customerClickedBanner', 'userClickedBanner']),

    replaceUrl(url) {
      if (this.internal) {
        return url.replace('customer/feature_pages', 'internal/feature_pages');
      } else if (this.customerId) {
        return `${url}?view_as=${this.customerId}`;
      } else {
        return url;
      }
    },

    clickedBanner(id) {
      if (this.internal) {
        this.userClickedBanner({ id: id });
      } else {
        this.customerClickedBanner({ id: id });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.p-special__item {
  a {
    color: #4c4948;
    text-decoration: none;
  }
  a:hover {
    opacity: .65
  }
}
</style>