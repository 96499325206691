<template>
  <div>
    <common-header :internal="internal" :maintenance="maintenance" :current_user_root="current_user_root"></common-header>
    <common-search-box :internal="internal"></common-search-box>
    <main id="main" class="l-main">
      <!-- BREADCRUMB -->
      <div class="c-breadcrumb">
        <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="c-breadcrumb__list c-grid">
          <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="c-breadcrumb__item">
            <a itemprop="item" :href="`${customerUrl}`| customerUrl()" class="c-breadcrumb__link" data-turbolinks="false">
              <span itemprop="name"><i class="ico ico-home"></i>トップ</span>
            </a>
            <meta itemprop="position" content="1">
          </li>
          <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="c-breadcrumb__item">
            <a itemprop="item" href="">
              <span itemprop="name">商品一覧</span>
            </a>
            <meta itemprop="position" content="2">
          </li>
        </ol>
      </div><!-- /BREADCRUMB -->
      <!-- HEADLINE -->
      <div class="c-headline">
        <div class="c-headline__inner c-grid">
          <div class="c-headline__body">
            <h2 class="c-headline__ttl">お気に入りアイテム一覧</h2>
            <p class="c-headline__dsc"></p>
          </div>
        </div>
      </div><!-- /HEADLINE -->
      <!-- PRODUCT-ARCHIVE -->
      <section class="p-product-archive">
        <div class="p-product-archive__inner c-grid">
          <div class="p-product-archive__body">
            <div class="p-product-archive__row">
              <ul class="p-product-archive__list">
                <li class="p-product-archive__item" v-for="(product, index) in productList" :key="index">
                  <a :href="customerUrl + '/products/' + product.id | customerUrl(view_as)" data-turbolinks="false" v-if="product.id">
                    <div class="c-flame">
                      <img :src="product.main_image_url" alt="" width="330" height="330" v-if="product.main_image_url">
                      <img src="/img/no-image.png" alt="" width="330" height="330" v-else>
                    </div>
                    <h3 class="p-product-archive__dsc">{{product.name}}</h3>
                    <!-- <p class="p-product-archive__num">{{product.supplier_code}} / {{product.code}}</p> -->
                  </a>
                </li>
              </ul>
            </div>
            <!-- PAGINATION -->
            <nav class="c-pagination" v-if="totalRows && totalRows/18 > 1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="18"
                first-number
                last-number
                class="c-pagination__list"
                prev-class="c-pagination__item is-prev"
                next-class="c-pagination__item is-next"
                page-class="c-pagination__item"
                @change="changePage"
              ></b-pagination>
            </nav>
            <!-- PAGINATION -->
          </div>
        </div>
      </section>
      <!-- /PRODUCT-ARCHIVE -->
    </main><!-- /MAIN -->
    <common-info-manager></common-info-manager>
    <common-footer :internal="internal"></common-footer>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  props: ['products', 'total_rows_default', 'view_as', 'internal', 'maintenance', 'current_user_root'],
  data() {
    return {
      customerUrl: this.internal ? process.env.VUE_APP_INTERNAL_URL : process.env.VUE_APP_CUSTOMER_URL,
      currentPage: 1,
      productList: [],
      totalRows: this.total_rows_default
    };
  },
  beforeMount() {
    const plugin = document.createElement('script');
    plugin.setAttribute(
      'src',
      '/js/index.bundle.js'
    );
    plugin.async = true;
    document.body.appendChild(plugin);
  },
  created() {
    this.productList = _.cloneDeep(this.products);
  },
  methods: {
    ...mapActions('product', ['likedProducts']),

    async changePage(page) {
      const data = await this.likedProducts({ page: page, internal: this.internal });
      if (data && data.data && data.data.products) {
        this.productList = _.cloneDeep(data.data.products);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.p-product-archive__row {
  min-height: 45vh;
}

@media only screen and (min-width: 1023px) and (max-width: 1100px)  {
  .p-product-archive__row {
    min-height: 60vh;
  }
}
</style>