<template>
  <article class="p-news-post-single" v-if="news">
    <ul class="c-meta-list">
      <li class="c-meta-list__item"><time class="c-meta c-meta--time" datetime="">{{news.posted_at | formatDate}}</time></li>
      <li class="c-meta-list__item"><span class="c-meta c-meta--cat">{{news.category_name}}</span></li>
    </ul>
    <h1 class="p-news-post-single__ttl">{{news.title}}</h1>
    <div class="p-news-post-single__edit">
      <figure>
        <div v-if="news.image_url" class="banner-image" :style="{ backgroundImage: 'url(' + news.image_url + ')'}"></div>
        <img src="/img/no-image.png" alt="" width="575" height="517" v-else class="news-thumb">
      </figure>
      <section class="p-output">
        <div class="p-output__inner c-grid">
          <div id="output" class="" v-html="modifyUrl(news.body)"/>
        </div>
      </section><!-- /EDITOR-OUTPUT -->
    </div>
  </article>
</template>
<script>
import Util from '@/utils/util.js';
import { mapActions } from 'vuex';

export default {
  props: ['news', 'internal'],
  data() {
    return {
      customerRootUrl: process.env.VUE_APP_CUSTOMER_URL,
      internalRootUrl: process.env.VUE_APP_INTERNAL_URL,
      customerId: Util.viewAsCustomer()
    };
  },

  created() {
    console.log('xxxx');
    if (this.internal) {
      this.userAccessNews({ id: this.news.id });
    } else {
      this.customerAccessNews({ id: this.news.id });
    }
  },
  methods: {
    ...mapActions('news', ['customerAccessNews', 'userAccessNews']),

    modifyUrl(url) {
      let endpoint = url;
      if (endpoint && endpoint.includes('<oembed')) {
        endpoint = endpoint.replaceAll('oembed', 'iframe');
        endpoint = endpoint.replaceAll('url', 'src');
        endpoint = endpoint.replaceAll('watch?v=', 'embed/');
      }
      const replaceCustomerUrl = '<a href="' + this.customerRootUrl;
      const replaceInternalUrl = '<a href="' + this.internalRootUrl;
      if (endpoint && endpoint.includes(replaceCustomerUrl)) {
        if (this.internal) {
          endpoint = endpoint.replaceAll(replaceCustomerUrl, replaceInternalUrl);
        } else if (this.customerId) {
          // const regexUrl = /href="(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])"?/;
          const regexUrl = /href="(http|ftp|https):\/\/([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])"?/gm;
          const listUrl = endpoint.match(regexUrl);
          const arrUrl = [];
          const urlRoot = this.customerRootUrl;
          const queryId = this.customerId;
          listUrl.forEach(function(element) {
            if (arrUrl.includes(element)) {
              return;
            }
            arrUrl.push(element);

            if (element.includes(urlRoot)) {
              const userIsViewUrl = element.includes('?') ? `${element.slice(0, -1)}&view_as=${queryId}"` : `${element.slice(0, -1)}?view_as=${queryId}"`;
              endpoint = endpoint.replaceAll(element, userIsViewUrl);
            }
          });
        }
      }

      return endpoint;
    }
  }
};
</script>

<style lang="scss" scoped>
  .news-thumb {
    height: 400px;
    width: 570px;
  }
  .p-output__inner {
    padding-bottom: 100px;
    width: 100%;
    min-width: 100%;
    padding: 0;
  }
  .banner-image {
    display: block;
    height: 400px;
    width: 570px;
    line-height: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    margin: 0 auto;
  }
  ::v-deep {
    .image-style-side,
    .image-style-align-left,
    .image-style-align-center,
    .image-style-align-right {
      max-width: 50%;
    }
    .image-style-side {
      float: right;
      margin-left: 1.5em;
    }
    .image-style-align-left {
      float: left;
      margin: 40px 5% 0 0!important;
    }
    .image-style-align-center {
      margin-left: auto;
      margin-right: auto;
    }
    .image-style-align-right {
      float: right;
      margin: 40px 0 0 5%!important;
    }
    .image.image_resized {
      max-width: 100%;
      display: block;
      box-sizing: border-box;
      img {
        width: 100%;
      }
      figcaption {
        display: block;
      }
    }
    .image {
      display: table;
      clear: both;
      text-align: center;
      img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        min-width: 50px;
      }
      figcaption {
        display: table-caption;
        caption-side: bottom;
        word-break: break-word;
        color: hsl(0, 0%, 20%);
        background-color: hsl(0, 0%, 97%);
        padding: .6em;
        font-size: .75em;
        outline-offset: -1px;
      }
    }
    .raw-html-embed {
      p {
        display: flex;
      }
      a {
        img {
          max-width: 100%!important;
          border: none!important;
        }
      }
    }

    #output figure.media {
      width: 100%;
      height: 420px;
      clear: both;
      iframe {
        width: 100%;
        height: 100%;
      }
    }

    #output {
      max-width: 880px;
      margin: 50px auto 0;
      box-sizing: border-box;
      background: #ffffff;
    }

    @media screen and (min-width:1280px) and (max-width:1900px) {
      #output {
        max-width: 750px;
        min-width: 750px;
        width: 750px;
      }
    }
  }
</style>