
import { API } from '../api';
import { API_INTERNAL } from '../apiInternal';

export const state = {

};

export const mutations = {

};

export const getters = {

};

export const actions = {
  async customerClickedBanner(context, query) {
    try {
      return await API.get(`/banners/${query.id}/clicked`);
    } catch (error) {
      return error.response.data;
    }
  },

  async userClickedBanner(context, query) {
    try {
      return await API_INTERNAL.get(`/banners/${query.id}/clicked`);
    } catch (error) {
      return error.response.data;
    }
  }
};