import Vue from 'vue';
import moment from 'moment';
import Util from '@/utils/util.js';

Vue.filter('formatDate', function(time, format = 'YYYY年MM月DD日 HH:mm') {
  return moment(time).format(format);
});

Vue.filter('customerUrl', function(url, isViewAsCustomer = false) {
  if (Util.viewAsCustomer() || isViewAsCustomer) {
    var query = url.split('?')[1];
    if (query) {
      return url + '&view_as=' + Util.viewAsCustomer();
    } else {
      return url + '?view_as=' + Util.viewAsCustomer();
    }
  }
  return url;
});
