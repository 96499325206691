<template>
  <div class="form-check form-check-inline mb-2">
    <label class="form-check-label mr-2" for="inlineCheckbox1" v-if="label">{{label}}</label>
    <toggle-button
      v-model="data"
      color="#28a745"
      :height="20"
      :width="40"
      class="form-check-input mb-0"
      :disabled="disabled"
      @change="changeValue"
    />
    <input type="hidden" :value="data" :name="name" v-if="name"/>
  </div>
</template>
<script>
export default {
  props: ['disabled', 'label', 'value', 'name'],
  data() {
    return {
      data: !!this.value
    };
  },

  methods: {
    changeValue() {
      this.$emit('input', this.data);
    }
  }
};
</script>