<template>
  <!-- FOOTER -->
  <footer id="footer" class="l-footer">
    <div class="l-footer__inner c-grid">
      <div class="l-footer__body">
        <div class="l-footer__nav">
          <ul class="l-footer__list">
            <li class="l-footer__item"><a :href="`${customerRootUrl}`| customerUrl()" data-turbolinks="false">ホーム</a></li>
            <li class="l-footer__item"><a :href="`${customerRootUrl}/news?internal=true`| customerUrl()" data-turbolinks="false">新着情報</a></li>
            <li class="l-footer__item"><a :href="`${customerRootUrl}/terms`| customerUrl()" data-turbolinks="false">利用規約</a></li>
            <li class="l-footer__item"><a href="https://www.denso-solution.com/privacy/" target="_blank">プライバシーポリシー<i class="c-icon c-icon--link"></i></a></li>
            <li class="l-footer__item"><a href="https://www.denso-solution.com/" target="_blank">デンソーソリューション<i class="c-icon c-icon--link"></i></a></li>
          </ul>
        </div>
        <div class="">
          <p class="l-footer__copy"><small>© DENSO SOLUTION JAPAN CORPORATION ALL Rights Reserved.</small></p>
        </div>
      </div>
    </div>
    <div class="c-button--totop">
      <img src="/img/btn-totop.png" alt="" width="" height="">
    </div>
  </footer><!-- /FOOTER -->
</template>

<script>
export default {
  props: [
    'internal'
  ],
  data() {
    return {
      customerRootUrl: this.internal ? process.env.VUE_APP_INTERNAL_URL : process.env.VUE_APP_CUSTOMER_URL
    };
  }
};
</script>

<style lang="scss" scoped>
  .l-footer__item {
    a:hover {
      color: #fff;
    }
  }
</style>