<template>
  <b-modal
    id="modal-info-manager"
    hide-header
    hide-footer
    centered
    >
    <div class="p-modal">
      <div class="p-modal__inner" v-if="manager">
        <div class="p-modal__img">
          <div class="user-image" :style="{ backgroundImage: 'url(' + manager.avatar_url + ')'}" v-if="manager.avatar_url"></div>
          <img src="/img/no-image.png" alt="" width="595" height="716" v-else>
        </div>
        <div class="p-modal__body">
          <h2>私が御社の担当セールスです</h2>
          <dl>
            <dt>{{manager.name}}</dt>
          </dl>
          <ul>
            <li>株式会社デンソーソリューション</li>
          </ul>
          <div class="c-address">
            <ul class="c-address__list">
              <li class="c-address__item">
                <a :href="`tel:${manager.phone_number}`"><img class="icon-phone" src="/img/phone.png" alt=""> {{manager.phone_number}}</a>
              </li>
              <li class="c-address__item">
                <a :href="`tel:${manager.tel_number}`"><i class="fas fa-mobile-alt icon-phone"></i> {{manager.tel_number}}</a>
              </li>
              <li class="c-address__item">
                <a :href="`mailto:${manager.email}?subject=営業スタッフへのご連絡&body=${encodedBody()}`"><i class="c-icon c-icon--mail-red"></i>{{manager.email}}</a>
              </li>
            </ul>
          </div>
          <a :href="`mailto:${manager.email}?subject=営業スタッフへのご連絡&body=${encodedBody()}`" class="p-modal__btn"><i class="c-icon c-icon--mail"></i>メールで問い合わせる</a>
          <div class="p-modal__comment">
            <h3>{{manager.name}}からのコメント</h3>
            <p v-if="manager.comment">{{manager.comment}}</p>
          </div>
        </div>
      </div>
      <div class="popup-modal-dismiss" @click="$bvModal.hide('modal-info-manager')">
        <a href="#" class="c-button c-button--dismiss"><i></i></a>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
export default {

  computed: {
    ...mapState('user', {
      manager: state => state.manager,
      currentUser: state => state.currentUser
    })
  },

  methods: {
    encodedBody() {
      return encodeURIComponent(`( ) お問い合わせ
( ) ご意見
( ) ご連絡事項
お問い合わせ内容は以下にご記入ください。
－－－－－－－－－－－－－－－－－－－


－－－－－－－－－－－－－－－－－－－
＜お客様情報＞
メールアドレス：${this.currentUser.email}
会社名：${this.currentUser.company_name}
氏名：${this.currentUser.name}
`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.user-image {
  display: inline-block;
  height: 350px;
  width: 290px;
  background-size: cover;
  background-position: center center;
  text-align: center;
}

.p-modal__body {
    max-height: 70vh;
    overflow-y: auto;
}

.fa-tty {
  margin-right: 13px;
  font-size: 25px;
}

.icon-phone {
  font-size: 25px;
  width: 35px;
}

.fa-mobile-alt {
  margin-left: 10px;
}
</style>