<template>
  <div>
    <common-header :internal="internal" :maintenance="maintenance" :current_user_root="current_user_root"></common-header>
    <common-search-box :internal="internal"></common-search-box>
    <main id="main" class="l-main">
      <!-- SLIDER-TOP -->
      <div class="swiper-container-wrap">
        <div class="swiper-container-outer">
          <div id="slider-top" class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(banner, index) in main_banners" :key="index" @click="clickedBanner(banner.id)" @auxclick="clickedBanner(banner.id)">
                <a :href="replaceUrl(banner.url)">
                  <img :src="banner.image_url" alt="" width="1500" height="900" v-if="banner.image_url" class="banner-image">
                </a>
              </div>
              <div class="swiper-slide" v-if="!main_banners.length">
              </div>
            </div>
            <div class="btn-slider--prev"></div>
            <div class="btn-slider--next"></div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div><!-- /SLIDER-TOP -->
      <!-- KEYWORD -->
      <div class="p-keyword">
        <div class="p-keyword__inner c-grid">
          <h3 class="p-keyword__ttl"><i class="c-icon c-icon--keyword"></i><span>注目ワード</span></h3>
          <ul class="p-keyword__list">
            <template v-for="(featured_keyword, index) in featured_keywords">
              <li class="p-keyword__item" :key="index">
                <a :href="`${customerRootUrl}/products/search?keyword=${featured_keyword.keyword}` | customerUrl()" data-turbolinks="false"> {{ featured_keyword.keyword }} </a>
              </li>
            </template>
          </ul>
        </div>
      </div><!-- /KEYWORD -->
      <!-- SEARCH -->
      <div class="p-search">
        <div class="p-search__inner c-grid">
          <form role="search" method="get" class="p-search__form" :action="`${customerRootUrl}/products/search`">
            <input type="hidden" name="view_as" :value="viewAsCustomer" v-if="viewAsCustomer"/>
            <input type="hidden" name="search_type" value="1"/>
            <input type="search" class="p-search__input" placeholder="商品名、キーワード、型番" v-model="keyword">
            <input type="hidden" name="keyword" v-model="textSearch">
            <button type="submit" value="検索" class="p-search__btn"><span>検索</span></button>
          </form>
          <div class="p-search__favorite">
            <a :href="`${customerRootUrl}/products/liked_products` | customerUrl()" data-turbolinks="false"><i class="c-icon c-icon--favorite"></i>お気に入りアイテムを表示</a>
          </div>
        </div>
      </div><!-- /SEARCH -->
      <!-- TOPICS -->
      <news-internal :list_news_internal="list_news_internal" v-if="internal"></news-internal><!-- /TOPICS -->
      <!-- RECOMMEND -->
      <section class="p-recommend">
        <div class="p-recommend__inner c-grid">
          <h2 class="c-title c-title--recommend"><span>おすすめ商品</span></h2>
          <div class="p-recommend__body">
            <div class="p-recommend__row">
              <ul class="p-recommend__list">
                <template v-for="(product, index) in recommended_products">
                  <li class="p-recommend__item" :key="index">
                    <a :href="`${customerRootUrl}/products/${product.id}` | customerUrl()" data-turbolinks="false">
                      <span class="c-icon c-icon--ranking">{{ index + 1 }}</span>
                      <div class="c-flame">
                        <img :src="product.main_image_url" alt="" width="330" height="330" v-if="product.main_image_url">
                        <img src="/img/no-image.png" alt="" width="330" height="330" v-else>
                      </div>
                      <h3 class="p-recommend__dsc">{{ product.name }}</h3>
                      <p class="p-recommend__num">{{ product.product_model }}</p>
                    </a>
                  </li>
                </template>
              </ul>
            </div>
            <!-- RECENT VIEW -->
            <div class="p-recommend__row">
              <h2 class="c-title c-title--check">最近チェックした商品</h2>
              <ul class="p-recommend__list">
                <template v-for="(product, index) in recent_products">
                  <li class="p-recommend__item" :key="index">
                    <a :href="`${customerRootUrl}/products/${product.id}`  | customerUrl()" data-turbolinks="false">
                      <div class="c-flame">
                        <img :src="product.main_image_url" alt="" width="330" height="330" v-if="product.main_image_url">
                        <img src="/img/no-image.png" alt="" width="330" height="330" v-else>
                      </div>
                      <h3 class="p-recommend__dsc">{{ product.name }}</h3>
                      <p class="p-recommend__num">{{ product.product_model }}</p>
                    </a>
                  </li>
                </template>
              </ul>
            </div><!-- RECENT VIEW -->
          </div>
        </div>
      </section><!-- /RECOMMEND -->
      <!-- FEATURE -->
      <section class="p-feature">
        <div class="p-feature__inner">
          <h2 class="c-title c-title--normal">おすすめ特集一覧</h2>
          <div class="p-feature__row">
            <!-- SLIDER-CAROUSEL -->
            <div class="swiper-container-wrap">
              <div class="swiper-container-outer swiper-container-outer--carousel">
                <div id="slider-carousel" class="swiper-container">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(banner, index) in recommend_banners" :key="index" @click="clickedBanner(banner.id)" @auxclick="clickedBanner(banner.id)">
                      <a :href="replaceUrl(banner.url)">
                        <div class="c-flame">
                          <img :src="banner.image_url" alt="" width="312" height="312" v-if="banner.image_url">
                          <img src="/img/no-image.jpg" alt="" width="312" height="312" v-else>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="btn-carousel--next"></div>
                  <div class="btn-carousel--prev"></div>
                  <div class="swiper-pagination"></div>
                </div>
              </div>
            </div>
            <!-- /SLIDER-CAROUSEL -->
          </div>
          <div class="p-feature__row c-grid">
            <ul class="p-feature__list">
              <template v-for="(banner, index) in under_recommend_banners">
                <li class="p-feature__item" :key="index" @click="clickedBanner(banner.id)" @auxclick="clickedBanner(banner.id)">
                  <a :href="replaceUrl(banner.url)">
                    <img :src="banner.image_url" alt="" width="700" height="240" class="recommend-banner-image">
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </section><!-- /FEATURE -->
         <!-- PRODUCTS -->
      <section class="p-products" id="sec-products">
        <div class="p-products__inner c-grid">
          <h2 class="c-title c-title--products">商品一覧</h2>
          <div class="p-products__body">
            <div class="p-products__grid">
              <div class="p-products__col" v-for="(category, index) in list_category.filter(_=> !_.parent_id)" :key="index">
                <a :href="`${customerRootUrl}/products/search?main_category_id=${category.id}` | customerUrl()" class="p-products__ttl">
                  <img :src="category.image_url" alt="" width="35" height="40" class="p-products__ico">
                  <span>{{category.name}}</span>
                </a>
                <ul class="p-products__list">
                  <li class="p-products__item" v-for="(sub, indexChild) in list_category.filter(_=> _.parent_id == category.id)" :key="indexChild">
                    <a :href="`${customerRootUrl}/products/search?sub_category_id=${sub.id}` | customerUrl()" data-turbolinks="false">
                      <span>{{sub.name}}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- /PRODUCTS -->
      <!-- SEARCH-MAKER -->
      <div class="c-search-maker">
        <div class="c-search-maker__inner c-grid">
          <h3 class="c-title c-title--sub">メーカー名から探す</h3>
          <div class="c-search-maker__body">
            <div class="c-search-maker__list">
              <div class="c-search-maker__item" v-for="(manufaturer, index) in manufaturers" :key="index">
                <a :href="`${customerRootUrl}/products/search?manufacturer_name=${manufaturer.name}` | customerUrl()" data-turbolinks="false" class="c-search-maker__button"> {{ manufaturer.name}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /SEARCH-MAKER -->
      <main-news :list_news="list_news" :internal="internal"></main-news>
    </main>
    <common-footer :internal="internal"></common-footer>
    <common-info-manager></common-info-manager>
  </div>
</template>

<script>
import Util from '@/utils/util.js';
import { mapActions } from 'vuex';
import {
  toFullwidthKana
} from 'japanese-string-utils';
export default {
  props: [
    'main_banners',
    'recommend_banners',
    'under_recommend_banners',
    'featured_keywords',
    'recommended_products',
    'list_news',
    'list_news_internal',
    'recent_products',
    'list_feature_pages',
    'list_category',
    'manufaturers',
    'internal',
    'maintenance',
    'current_user_root',
    'customer_email'
  ],
  data() {
    return {
      customerRootUrl: this.internal ? process.env.VUE_APP_INTERNAL_URL : process.env.VUE_APP_CUSTOMER_URL,
      csrfToken: Util.getCsrfToken(),
      viewAsCustomer: Util.viewAsCustomer(),
      keyword: '',
      textSearch: '',
      webPPDomain: process.env.WEBPP_URL,
    };
  },

  beforeMount() {
    const plugin = document.createElement('script');
    plugin.setAttribute(
      'src',
      '/js/index.bundle.js'
    );
    plugin.async = true;
    document.body.appendChild(plugin);
  },

  watch: {
    keyword() {
      this.textSearch = toFullwidthKana(this.keyword);
    }
  },

  methods: {
    ...mapActions('banner', ['customerClickedBanner', 'userClickedBanner']),

    openProductDetail(productId) {
      window.location.href = `${this.customerRootUrl}/products/${productId}`;
    },

    replaceUrl(url) {
      // if (url === process.env.WEBPP_PROD_URL) {
      //   if (!this.customer_email || this.viewAsCustomer) {
      //     return '';
      //   }
      //   return '/customer/web_proposals';
      //   // return `${this.webPPDomain}/auth/dsbox/login?type=customer&email=${this.customer_email}`;
      // }
      if (this.internal) {
        return url.replace('customer/feature_pages', 'internal/feature_pages');
      } else if (this.viewAsCustomer) {
        return `${url}?view_as=${this.viewAsCustomer}`;
      } else {
        return url;
      }
    },

    clickedBanner(id) {
      if (this.internal) {
        this.userClickedBanner({ id: id });
      } else {
        this.customerClickedBanner({ id: id });
      }
    },

    toASCII(chars) {
      var ascii = '';
      for (var i = 0, l = chars.length; i < l; i++) {
        var c = chars[i].charCodeAt(0);

        // make sure we only convert half-full width char
        if (c >= 0xFF00 && c <= 0xFFEF) {
          c = 0xFF & (c + 0x20);
        }

        ascii += String.fromCharCode(c);
      }

      return ascii;
    }
  }
};
</script>

<style lang="scss" scoped>
.c-search-maker__list {
  display: block;
  .c-search-maker__item {
    display: inline-block;
    margin: 5px 15px 5px 0px;
    max-width: 100%;
    overflow: hidden;
  }
}

.c-search-maker__button {
  white-space: nowrap;
}

.c-flame {
background: white;
}

.p-search__input {
  width: calc(100% - 100px);
}

.p-search__btn {
  border-inline: 2px solid #dc0032;
  border: none;
}

.p-keyword__ttl {
  font-weight: bold;
}

.p-search__favorite {
  a:hover {
    color: white;
  }
}

.recommend-banner-image {
  background-size: cover;
  background-position: center center;
}

@media (min-width: 1024px) {
  .recommend-banner-image {
    height: 120px;
    width: 350px;
  }
}

@media (max-width: 1023px) {
  .recommend-banner-image {
    height: 80px;
    width: 230px;
  }
}

</style>