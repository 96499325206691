
import { API } from '../api';
import { API_INTERNAL } from '../apiInternal';

export const state = {
};

export const mutations = {
};

export const getters = {

};

export const actions = {
  async searchProducts(context, query) {
    try {
      if (query.internal) {
        return await API_INTERNAL.get('/products/search', query);
      } else {
        return await API.get('/products/search', query);
      }
    } catch (error) {
      return error.response.data;
    }
  },
  async likedProducts(context, query) {
    try {
      if (query.internal) {
        return await API_INTERNAL.get('/products/liked_products', query);
      } else {
        return await API.get('/products/liked_products', query);
      }
    } catch (error) {
      return error.response.data;
    }
  },
  async likeProduct(context, query) {
    try {
      if (query.internal) {
        return await API_INTERNAL.post('/products/' + query.id + '/like', query);
      } else {
        return await API.post('/products/' + query.id + '/like', query);
      }
    } catch (error) {
      return error.response.data;
    }
  },
  async unLikeProduct(context, query) {
    try {
      if (query.internal) {
        return await API_INTERNAL.post('/products/' + query.id + '/unlike', query);
      } else {
        return await API.post('/products/' + query.id + '/unlike', query);
      }
    } catch (error) {
      return error.response.data;
    }
  }

};