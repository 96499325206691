<template>
  <!-- LOADING -->
  <div id="loading" class="p-loading">
    <div class="p-loading__img c-spinner">
      <span>Loading...</span>
    </div>
  </div><!-- /LOADING -->
</template>

<script>
export default {

};
</script>

<style>

</style>