class Util {
  static getCsrfToken() {
    const tokenDom = document.head.querySelector('meta[name="csrf-token"]');
    return tokenDom ? tokenDom.content : null;
  }

  static viewAsCustomer() {
    const uri = window.location.search.substring(1);
    const params = new URLSearchParams(uri);
    return params.get('view_as');
  }

  static getQuery(type) {
    const uri = window.location.search.substring(1);
    const params = new URLSearchParams(uri);

    if (type === 'page') {
      return params.get(type) || 0;
    }

    return params.get(type) || '';
  }

  static updateUrlWithQuery(obj) {
    const params = new URLSearchParams(window.location.search);

    for (const [key, value] of Object.entries(obj)) {
      console.log(`${key}: ${value}`);
      params.set(key, value);
    }

    window.history.pushState({}, '', decodeURIComponent(`${window.location.pathname}?${params}`));
  }
}

export default Util;
