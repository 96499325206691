<template>
  <div>
    <div id="wrapper" class="l-wrapper">
      <common-header :internal="internal" :maintenance="maintenance" :current_user_root="current_user_root"></common-header>
      <common-search-box :internal="internal"></common-search-box>
      <!-- MAIN -->
      <main id="main" class="l-main">
        <!-- BREADCRUMB -->
        <div class="c-breadcrumb">
          <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="c-breadcrumb__list c-grid">
            <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="c-breadcrumb__item">
              <a itemprop="item" :href="`${customerRootUrl}`| customerUrl()" class="c-breadcrumb__link" data-turbolinks="false">
                <span itemprop="name"><i class="ico ico-home"></i>トップ</span>
              </a>
              <meta itemprop="position" content="1">
            </li>
            <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="c-breadcrumb__item">
              <a itemprop="item" :href="`${customerRootUrl}/banners`| customerUrl()" data-turbolinks="false">
                <span itemprop="name">特集一覧</span>
              </a>
              <meta itemprop="position" content="2">
            </li>
            <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="c-breadcrumb__item item__special-title">
              <a itemprop="item" href="" data-turbolinks="false">
                <span itemprop="name" class="c-breadcrumb__title-page"> {{ feature_page.title }} </span>
              </a>
              <meta itemprop="position" content="3">
            </li>
          </ol>
        </div><!-- /BREADCRUMB -->
        <!-- HEADLINE -->
        <div class="c-headline">
          <div class="c-headline__inner c-grid">
            <div class="c-headline__body">
              <h2 class="c-headline__ttl"> {{ feature_page.title }}</h2>
            </div>
          </div>
        </div><!-- /HEADLINE -->
        <!-- EDITOR-OUTPUT -->
        <section class="p-output">
          <div class="p-output__inner c-grid">
            <div id="output" class="" v-html="modifyUrl(feature_page.body)"/>
          </div>
        </section>
        <!-- /EDITOR-OUTPUT -->
      </main><!-- /MAIN -->
      <common-info-manager></common-info-manager>
      <common-footer :internal="internal"></common-footer>
    </div>
  </div>
</template>

<script>
import Util from '@/utils/util.js';

export default {
  props: ['feature_page', 'internal', 'maintenance', 'current_user_root'],
  data() {
    return {
      customerRootUrl: this.internal ? process.env.VUE_APP_INTERNAL_URL : process.env.VUE_APP_CUSTOMER_URL,
      customerUrl: process.env.VUE_APP_CUSTOMER_URL,
      internalUrl: process.env.VUE_APP_INTERNAL_URL,
      customerId: Util.viewAsCustomer()
    };
  },

  beforeMount() {
    const plugin = document.createElement('script');
    plugin.setAttribute(
      'src',
      '/js/index.bundle.js'
    );
    plugin.async = true;
    document.body.appendChild(plugin);
  },

  methods: {
    modifyUrl(url) {
      let endpoint = url;
      if (endpoint && endpoint.includes('<oembed')) {
        endpoint = endpoint.replaceAll('oembed', 'iframe');
        endpoint = endpoint.replaceAll('url', 'src');
        endpoint = endpoint.replaceAll('watch?v=', 'embed/');
      }

      const replaceCustomerUrl = '<a href="' + this.customerUrl;
      const replaceInternalUrl = '<a href="' + this.internalUrl;
      if (endpoint && endpoint.includes(replaceCustomerUrl)) {
        if (this.internal) {
          endpoint = endpoint.replaceAll(replaceCustomerUrl, replaceInternalUrl);
        } else if (this.customerId) {
          // const regexUrl = /href="(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])"?/;
          const regexUrl = /href="(http|ftp|https):\/\/([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])"?/gm;
          const listUrl = endpoint.match(regexUrl);
          const arrUrl = [];
          const urlRoot = this.customerUrl;
          const queryId = this.customerId;
          listUrl.forEach(function(element) {
            if (arrUrl.includes(element)) {
              return;
            }
            arrUrl.push(element);

            if (element.includes(urlRoot)) {
              const userIsViewUrl = element.includes('?') ? `${element.slice(0, -1)}&view_as=${queryId}"` : `${element.slice(0, -1)}?view_as=${queryId}"`;
              endpoint = endpoint.replaceAll(element, userIsViewUrl);
            }
          });
        }
      }

      return endpoint;
    }
  }
};
</script>
<style lang="scss" scoped>
  .item__special-title {
    a {
      width: 250px;
    }
  }
  .c-breadcrumb__title-page {
    width: 100%;
    white-space: pre;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    margin: 0;
    padding: 0.5rem 0;
  }
  .c-headline__ttl {
    white-space: pre-line;
    line-height: 1.2;
  }

  .p-output__inner {
    padding-bottom: 100px;
  }
  ::v-deep {
    .image-style-side,
    .image-style-align-left,
    .image-style-align-center,
    .image-style-align-right {
      max-width: 50%;
    }
    .image-style-side {
      float: right;
      margin-left: 1.5em;
    }
    .image-style-align-left {
      float: left;
      margin: 40px 5% 0 0!important;
    }
    .image-style-align-center {
      margin-left: auto;
      margin-right: auto;
    }
    .image-style-align-right {
      float: right;
      margin: 40px 0 0 5%!important;
    }
    .image.image_resized {
      max-width: 100%;
      display: block;
      box-sizing: border-box;
      img {
        width: 100%;
      }
      figcaption {
        display: block;
      }
    }
    .image {
      display: table;
      clear: both;
      text-align: center;
      img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        min-width: 50px;
      }
      figcaption {
        display: table-caption;
        caption-side: bottom;
        word-break: break-word;
        color: hsl(0, 0%, 20%);
        background-color: hsl(0, 0%, 97%);
        padding: .6em;
        font-size: .75em;
        outline-offset: -1px;
      }
    }
    .raw-html-embed {
      p {
        display: flex;
      }
      a {
        img {
          width: 100%;
          max-width: 100%!important;
          border: none!important;
        }
      }
    }
    #output figure.media {
      width: 100%;
      height: 500px;
      clear: both;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
    @media screen and (min-width:1280px) and (max-width:1900px) {
      #output {
        max-width: 880px;
        min-width: 880px;
        width: 880px;
      }
    }
  }
</style>