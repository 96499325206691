import axios from 'axios';
import Util from '@/utils/util.js';

export const API_INTERNAL = {
  setHeader() {
    const tokenDom = document.querySelector('meta[name=csrf-token]');
    if (tokenDom) {
      const csrfToken = tokenDom.content;
      axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
      axios.defaults.headers.common.Accept = 'application/json';
      axios.defaults.headers.common['Content-Type'] = 'application/json';
    }
  },

  get(resource, data) {
    this.setHeader();
    let url = process.env.VUE_APP_INTERNAL_URL + resource;

    if (Util.viewAsCustomer()) {
      url += '?view_as=' + Util.viewAsCustomer();
    }
    return axios.get(url, {
      params: data
    });
  },

  post(resource, data) {
    this.setHeader();
    return axios.post(process.env.VUE_APP_INTERNAL_URL + resource, data);
  },

  put(resource, data) {
    this.setHeader();
    return axios.put(process.env.VUE_APP_INTERNAL_URL + resource, data);
  },

  delete(resource) {
    this.setHeader();
    return axios.delete(process.env.VUE_APP_INTERNAL_URL + resource);
  }

};