export const state = {
  loading: false,
  success: null,
  submitted: false
};

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_SUCCESS(state, success) {
    state.success = success;
  },

  SET_SUBMITTED(state, submitted) {
    state.submitted = submitted;
  }

};

export const getters = {
};

export const actions = {
  setLoading(context, loading) {
    context.commit('SET_LOADING', loading);
  },

  setSuccess(context, success) {
    context.commit('SET_SUCCESS', success);
  },

  setSubmitted(context, submitted) {
    context.commit('SET_SUBMITTED', submitted);
  }
};
