<template>
  <!-- MODAL-PRICE -->
  <div id="js-modal-price" class="mfp-hide white-popup-block">
    <div class="p-modal p-modal--price">
      <div class="p-modal__inner p-modal__inner--price">
        <div class="p-modal__sp-canvas">
          <h3 class="p-modal__ttl">ルート毎の価格</h3>
          <div class="table-responsive">
            <table class="p-modal__list-table">
              <thead>
                <tr>
                  <th class="mw-250"></th>
                  <th class="col mw-125 p-table">ガイドライン価格</th>
                  <th v-for="(branch, index) in branches" :key="index" class="mw-125 p-table">{{ branch.name }}</th>
                </tr>
              </thead>
              <tbody>
                <tr  v-for="(pricing, pricing_index) in productPricings" :key="pricing_index">
                  <td>{{ pricing.name }}</td>
                  <template v-if="role == 'super_admin' || role == 'ads_moderator'">
                    <td class="p-table">
                      <i>
                        {{ getPricing(null, pricing.value) }}
                      </i>
                    </td>
                    <td class="p-table" v-for="(branch, branch_index) in branches" :key="branch_index">
                      <i>
                        {{ getPricing(branch.id, pricing.value) }}
                      </i>
                    </td>
                  </template>
                  <template v-else>
                    <td class="p-table">
                      <i>
                        {{ getPricing(null, pricing.value) }}
                      </i>
                    </td>
                    <template v-for="(branch, branch_index) in branches">
                      <td class="list-table_center p-table" :key="branch_index" v-if="branch.id !== branch_id" >
                        <span> - </span>
                      </td>
                      <td class="p-table" :key="branch_index" v-else>
                        <i>
                          {{ getPricing(branch.id, pricing.value) }}
                        </i>
                      </td>
                    </template>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="popup-modal-dismiss">
        <a href="#" class="c-button c-button--dismiss"><i></i></a>
      </div>
    </div>
  </div><!-- /MODAL-PRICE -->
</template>

<script>
import { ProductPricings } from '@/admin/utils/constant';

export default {
  props: ['branches', 'pricings', 'branch_id', 'role', 'product'],

  data() {
    return {
      productPricings: ProductPricings
    };
  },

  methods: {
    getPricing(branchId, priceName) {
      const data = this.pricings.find(_ => _.branch_id === branchId);
      if (data) {
        if (priceName === 'price_reference_text' || priceName === 'price_note') {
          return data[priceName] || '';
        }
        return data[priceName] ? data[priceName] + ' 円' : '-';
      }
      return '-';
    }
  }
};
</script>

<style lang="scss" scoped>
  td {
    text-align: left!important;
  }
  .list-table_center {
    text-align: center !important;
  }

  .p-table {
    padding: 0.75rem;
  }

  .mw-125 {
    min-width: 125px;
  }

  .mw-250 {
    min-width: 250px;
  }
</style>