import '../stylesheets/customer'; // This file will contain your custom CSS
import Vue from 'vue';
import {
  BootstrapVue
} from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate';
import { ToggleButton } from 'vue-js-toggle-button';

import ja from '../strings/validator/ja.json';

// Import vuex stores
import store from '../src/customer/stores';
import '../src/customer/filters/filters';

import * as rules from 'vee-validate/dist/rules';
const jQuery = require('jquery');
window.$ = jQuery;
window._ = require('lodash');
const toastr = require('toastr');
window.toastr = toastr;
require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();

// Plugin configurations
Vue.config.devtools = true;
Vue.use(BootstrapVue);

// START: vee-validation configuration
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ToggleButton', ToggleButton);
localize('ja', ja);

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

extend('url', value => {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(value);
});
// END: vee-validation configuration

// Automatically import components
const files = require.context('../src/customer', true, /\.vue$/i);
files.keys().map((key) => {
  const component = key
    .split('/')
    .pop()
    .split('.')[0];
  Vue.component(component, files(key).default);
});

// We have to re-create vue app when change the page url
document.addEventListener('turbolinks:load', () => {
  new Vue({
    locale: 'ja',
    store
  }).$mount('#application');
});

toastr.options = {
  closeButton: false,
  positionClass: 'toast-top-right',
  preventDuplicates: true,
  onclick: null,
  showDuration: '500',
  hideDuration: '500',
  timeOut: '5000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut'
};
