<template>
  <div>
    <common-header :internal="internal" :maintenance="maintenance" :current_user_root="current_user_root"></common-header>
    <common-search-box :internal="internal"></common-search-box>

    <!-- MAIN -->
    <main id="main" class="l-main">
      <!-- BREADCRUMB -->
      <div class="c-breadcrumb">
        <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="c-breadcrumb__list c-grid">
          <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="c-breadcrumb__item">
            <a itemprop="item" :href="`${customerUrl}`| customerUrl()" class="c-breadcrumb__link" data-turbolinks="false">
              <span itemprop="name"><i class="ico ico-home"></i>トップ</span>
            </a>
            <meta itemprop="position" content="1">
          </li>
          <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="c-breadcrumb__item">
            <a itemprop="item" href="">
              <span v-if="!internal" itemprop="name">新着情報</span>
              <span v-else itemprop="name">新着情報</span>
            </a>
            <meta itemprop="position" content="2">
          </li>
        </ol>
      </div><!-- /BREADCRUMB -->
      <!-- HEADLINE -->
      <div class="c-headline">
        <div class="c-headline__inner c-grid">
          <div class="c-headline__body">
            <h2 v-if="!internal" class="c-headline__ttl">新着情報</h2>
            <h2 v-else class="c-headline__ttl">新着情報</h2>
          </div>
        </div>
      </div><!-- /HEADLINE -->
      <!-- NEWS-MAIN -->
      <div class="l-news-main c-grid">
        <!-- NEWS-MAIN > CONTENT -->
        <div class="l-news-main__content">
          <!-- NEWS-POST LOOP -->
          <list-news :listNews="listNews" v-if="!isPreview" @input="showNewsDetail"></list-news>
          <news-detail :news="listNews[indexDetail]" :internal="internal" v-if="isPreview"></news-detail>
          <!-- /NEWS-POST LOOP -->
          <!-- PAGINATION -->
            <nav class="c-pagination" v-if="totalRows && !isPreview && totalRows/8 > 1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="8"
                first-number
                last-number
                class="c-pagination__list"
                prev-class="c-pagination__item is-prev"
                next-class="c-pagination__item is-next"
                page-class="c-pagination__item"
                @change="changePage"
              ></b-pagination>
            </nav>
          <!-- PAGINATION -->
          <div class="c-pager" role="navigation" v-if="isPreview">
            <div class="c-pager__list">
              <div class="c-pager__item c-pager__item--prev" :class="indexDetail == 0 && currentPage == 1? 'disable': ''">
                <a @click="prevNews" rel="prev"><span class="c-pager__icon c-pager__icon--prev"></span>前へ</a>
              </div>
              <div class="c-pager__item c-pager__item--nav-center">
                <a @click="backToList" rel="center">一覧へ戻る</a>
              </div>
              <div class="c-pager__item c-pager__item--next"  :class="indexDetail == listNews.length - 1 && currentPage* 8 > totalRows? 'disable': ''">
                <a @click="nextNews" rel="next">次へ<span class="c-pager__icon c-pager__icon--next"></span></a>
              </div>
            </div>
          </div>
        </div><!-- /NEWS-MAIN > CONTENT -->
        <!-- NEWS-MAIN > SIDE -->
        <aside class="l-news-main__side">
          <div class="p-side-recent mb-4" v-if="internal">
            <h3 class="c-title c-title--side">タイプ</h3>
            <b-form-select v-model="newsScopeSelected" :options="newsScope" class="c-selectbox" @change="changeNewsScope">
            </b-form-select>
          </div>
          <div class="p-side-cat">
            <h3 class="c-title c-title--side">カテゴリー</h3>
            <ul class="p-side-cat__list">
              <li class="p-side-cat__item" :class="!category ? 'active' : ''"><a @click="changeCategory(null)">すべて</a></li>
              <li class="p-side-cat__item" :class="category == 'annoucement' ? 'active' : ''"><a @click="changeCategory('annoucement')">お知らせ</a></li>
              <li class="p-side-cat__item" :class="category == 'event' ? 'active' : ''"><a @click="changeCategory('event')">イベント</a></li>
              <li class="p-side-cat__item" :class="category == 'new_product' ? 'active' : ''"><a @click="changeCategory('new_product')">新規商品</a></li>
            </ul>
          </div>
          <div class="p-side-recent">
            <h3 class="c-title c-title--side">最新のニュース</h3>
            <ul class="p-side-recent__list">
              <li class="p-side-recent__item" v-for="(news, index) in newsLatest" :key="index">
                <a @click="newsDetail(news)" class="p-side-recent__link">
                  <ul class="c-meta-list">
                    <li class="c-meta-list__item"><time class="c-meta c-meta--time" datetime="">{{news.posted_at | formatDate}}</time></li>
                    <li class="c-meta-list__item"><span class="c-meta c-meta--cat">{{news.category_name}}</span></li>
                  </ul>
                  <h4 class="p-side-recent__ttl">{{news.title}}</h4>
                </a>
              </li>
            </ul>
          </div>
          <div class="p-side-recent">
            <h3 class="c-title c-title--side">過去のニュース</h3>
            <b-form-select v-model="year" :options="newsYearSelectOptions" class="c-selectbox" @change="changeYear">
            </b-form-select>
          </div>
        </aside><!-- /NEWS-MAIN > SIDE -->
      </div><!-- /NEWS-MAIN -->
    </main><!-- /MAIN -->
    <common-info-manager></common-info-manager>
    <common-footer :internal="internal"></common-footer>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import { NewsScope } from '@/customer/utils/constant';

export default {
  props: [
    'news_latest',
    'total_rows',
    'list_news',
    'last_news',
    'news',
    'current_page',
    'internal',
    'news_internal_latest',
    'last_internal_news',
    'maintenance',
    'current_user_root'
  ],
  data() {
    return {
      customerUrl: this.internal ? process.env.VUE_APP_INTERNAL_URL : process.env.VUE_APP_CUSTOMER_URL,
      currentPage: 1,
      totalRows: this.total_rows,
      listNews: _.cloneDeep(this.list_news),
      category: null,
      year: { start: null, end: null },
      newsYearSelectOptions: [],
      isPreview: false,
      indexDetail: null,
      newsScope: NewsScope,
      newsScopeSelected: 'internal',
      newsLatest: []
    };
  },

  computed: {
    yearRange() {
      const range = [];
      let lastYear = null;
      if (this.last_news) {
        lastYear = moment(this.last_news.posted_at).year();
      }
      const uri = window.location.search.substring(1);
      const params = new URLSearchParams(uri);

      if (this.internal && params.get('internal') && this.newsScopeSelected === 'internal') {
        lastYear = moment(this.last_internal_news.posted_at).year();
      }

      if (lastYear && this.newsLatest && this.newsLatest[0]) {
        const latestYear = moment(this.newsLatest[0].posted_at).year();
        if (lastYear <= latestYear) {
          for (let index = 0; index <= latestYear - lastYear; index++) {
            const year = lastYear + index;
            const option = {
              value: { start: year + '-1-1', end: year + '-12-31' }, text: year + '年'
            };
            range.push(option);
          }
        }
      }
      range.push({ value: { start: null, end: null }, text: '年を選択してください' });
      return range.reverse();
    }
  },

  beforeMount() {
    const plugin = document.createElement('script');
    plugin.setAttribute(
      'src',
      '/js/index.bundle.js'
    );
    plugin.async = true;
    document.body.appendChild(plugin);
  },

  mounted() {
    if (!this.internal) {
      this.newsLatest = this.news_latest;
    }
    this.newsYearSelectOptions = this.yearRange;
  },

  created() {
    if (this.news) {
      this.newsDetail(this.news);
      this.currentPage = this.current_page;
    }

    const uri = window.location.search.substring(1);
    const params = new URLSearchParams(uri);
    const query = params.get('internal');
    if (query === 'true') {
      this.newsScopeSelected = 'internal';
      this.newsLatest = this.news_internal_latest;
    } else {
      this.newsScopeSelected = 'public';
      this.newsLatest = this.news_latest;
    }
  },

  methods: {
    ...mapActions('news', ['searchNews', 'searchNewsInternal']),

    async changePage(page) {
      let data = null;
      if (this.internal) {
        const internalQuery = this.newsScopeSelected === 'internal';
        data = await this.searchNewsInternal({ 'q[category_cont]': this.category, 'q[posted_at_gteq]': this.year.start, 'q[posted_at_lteq]': this.year.end, page: page, internal: internalQuery });
      } else {
        data = await this.searchNews({ 'q[category_cont]': this.category, 'q[posted_at_gteq]': this.year.start, 'q[posted_at_lteq]': this.year.end, page: page });
      }

      if (data && data.data && data.data.list_news) {
        this.listNews = _.cloneDeep(data.data.list_news);
        this.totalRows = data.data.total_rows;
      }
    },

    async changeCategory(category) {
      this.category = category;
      this.currentPage = 1;
      await this.changePage(1);
      this.isPreview = false;
    },

    async changeYear(category) {
      this.currentPage = 1;
      await this.changePage(1);
      this.isPreview = false;
    },

    showNewsDetail(index) {
      this.indexDetail = index;
      this.isPreview = true;
    },

    async newsDetail(news) {
      const index = this.listNews.findIndex(_ => _.id === news.id);
      if (index >= 0) {
        this.indexDetail = index;
      } else {
        this.newsScopeSelected = 'internal';
        this.category = null;
        this.year = { start: null, end: null };
        this.currentPage = 1;
        await this.changePage(1);
        const index = this.listNews.findIndex(_ => _.id === news.id);
        this.indexDetail = index;
      }
      this.isPreview = true;
    },

    backToList() {
      this.isPreview = false;
    },

    async prevNews() {
      this.indexDetail -= 1;
      this.isPreview = false;
      if (this.indexDetail === -1 && this.currentPage > 1) {
        this.currentPage -= 1;
        await this.changePage(this.currentPage);
        this.indexDetail = this.listNews.length - 1;
      }
      this.$nextTick(() => {
        this.isPreview = true;
      });
    },

    async nextNews() {
      this.indexDetail += 1;
      this.isPreview = false;
      if (this.indexDetail === this.listNews.length && this.currentPage * 8 < this.totalRows) {
        this.currentPage += 1;
        await this.changePage(this.currentPage);
        this.indexDetail = 0;
      }
      this.$nextTick(() => {
        this.isPreview = true;
      });
    },

    async changeNewsScope() {
      this.year = { start: null, end: null };
      await this.changePage(1);
      if (this.newsScopeSelected === 'internal') {
        this.newsLatest = this.news_internal_latest;
      } else {
        this.newsLatest = this.news_latest;
      }
      this.newsYearSelectOptions = this.yearRange;

      this.isPreview = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.p-side-cat__list {
  cursor: pointer;
  .active {
    color: #dc0032;
  }
}

.p-side-recent__link {
  cursor: pointer;
}

.c-pager__list {
  .c-pager__item  {
    cursor: pointer;
    a:hover {
      color: #fff;
      background: #4a4a4a;
    }
  }
  .disable {
    cursor: not-allowed!important;
    pointer-events: none;
    a {
      color: #fff;
      background: #4a4a4a;
      opacity: .65;
    }
    span:after {
      border-color: #fff;
    }
  }
}
</style>