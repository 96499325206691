<template>
  <div>
    <div id="loading" class="p-loading" style="display: block;" v-if="loading">
      <div class="p-loading__img c-spinner">
        <span>Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('system', {
      loading: state => state.loading
    })
  }
};
</script>
<style lang="scss" scoped>

</style>
