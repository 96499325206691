<template>
  <div>
    <div class="p-news-post" v-for="(news, index) in listNews" :key="index">
      <a @click="showNewsDetail(index)" class="p-news-post__inner">
        <div class="p-news-post__img">
          <!-- <img :src="news.image_url" alt="" width="440" height="320" v-if="news.image_url"> -->
          <div v-if="news.image_url" class="banner-image" :style="{ backgroundImage: 'url(' + news.image_url + ')'}"></div>
          <img src="/img/no-image.png" alt="" width="440" height="320" v-else>
        </div>
        <div class="p-news-post__body">
          <ul class="c-meta-list">
            <li class="c-meta-list__item"><time class="c-meta c-meta--time" datetime="">{{news.posted_at | formatDate}}</time></li>
            <li class="c-meta-list__item"><span class="c-meta c-meta--cat">{{news.category_name}}</span></li>
          </ul>
          <h2 class="p-news-post__ttl">{{news.title}}</h2>
          <div v-html="news.body.slice(0, 100)" class="p-news-post__dsc"> </div>
        </div>
      </a>
    </div>
  </div>
</template>
<script>

export default {
  props: ['listNews'],

  methods: {
    showNewsDetail(index) {
      this.$emit('input', index);
    }
  }
};
</script>

<style lang="scss" scoped>
.p-news-post__img {
  img {
    height: 170px;
    width: 230px;
  }
}

.p-news-post__inner {
  cursor: pointer;
}

.banner-image {
  display: inline-block;
  height: 170px;
  width: 230px;
  line-height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  text-align: center;
  border: 1px solid #ddd;
  box-sizing: border-box;
}
</style>