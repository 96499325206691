<template>
  <div class="cart-container">
    <button @click="showCart" class="show-cart-btn" v-if="!this.is_show_cart">カートを表示</button>
    <div class="cart row" v-if="this.is_show_cart">
      <ul class="col-9 m-l-10 row">
        <div class="p-10-sticky">
          <p class="p-10">提案書／見積書生成する商品：<b style="color: red;">{{selectedProducts.length}}</b>件</p>
        </div>
        <li class="p-product-archive__item col-1" v-for="(product, index) in selectedProducts" :key="index">
          <button @click="removeProduct(product)" class="cancel-product">X</button>
          <div class="e-flame">
            <div class="item-image d-flex align-items-center">
              <img :src="product.main_image_url" alt="" width="330" height="330" v-if="product.main_image_url">
              <img src="/img/no-image.png" alt="" width="330" height="330" v-else>
            </div>
            <p style="overflow: auto;">{{product.name}}</p>
            <p>{{product.product_model}}</p>
            <!-- <p class="inventory"
              v-html="inventory(product)"
              v-if="!(!internal && !product.show_public_inventory || !internal && !product.show_inventory)"></p> -->
          </div>
        </li>
      </ul>

      <div class="col-2 sticky-position">
        <ul class="export-class">
          <li class="c-sidenav__item">
            <button class="btn-export-excel" @click="removeProduct()">カート内削除</button>
            <div v-for="(key, value) in exportModes" v-if="!selectedProducts.length"
              :key="key"
            >
              <button class="btn-export-excel">{{key}}</button>
            </div>
            <form class=""
              v-if="selectedProducts.length"
              action="/internal/products/export"
              v-for="(key, value) in exportModes"
              :key="key" :ref="`${value}_form`"
              >
              <input v-if="selectedProducts.length" type="hidden" name="id" :value="selectedProducts[0].id"/>
              <input type="hidden" name="mode" :value="value"/>
              <input type="hidden" name="is_from_cart" :value="true"/>
              <input type="hidden" name="export_image" :value="exportImage"/>
              <input type="hidden" name="export_product_ids" :value="selectedProducts.map(x => x.id)"/>
              <b-button id="show-btn" @click="warning(value)" v-if="!(selectedProducts.length < 11 && value != 'estimation_all')">{{key}}</b-button>
              <button type="submit" class="btn-export-excel" v-else>{{key}}</button>
            </form>
          </li>
        </ul>
      </div>
      <button @click="showCart" class="cancel-all-product">X</button>
    </div>
    <div>
      <b-modal id="bv-modal-example" hide-footer>
        <div class="d-block text-center">
          <div v-if="exportMode == 'estimation_all'">
            <h3>見積書作成</h3>
            <div class="d-flex justify-content-center">
              <h3>画像有り</h3>
              <div style="width: 10px;"></div>
              <toggle name="export_image" v-model="exportImage"></toggle>
            </div>
            <h5>カート内の商品の見積書を作成します。</h5>
          </div>
          <h6 class="text-danger" v-if="this.selectedProducts.length >= 11">{{ warningMessage() }}</h6>
        </div>
        <div class="d-flex justify-content-center">
          <b-button class="mt-3 modal-btn" block @click="submitForm()">作成</b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: [
    'products',
    'product_variants',
    'selected_products',
    'from_show_page',
    'is_show_cart',
    'current_user_root'
  ],

  data() {
    return {
      selectedProducts: this.product_variants || this.selected_products || [],
      exportModes: {
        suggestion_letter_all: '提案レター作成',
        estimation_all: '見積書作成'
      },
      exportMode: null,
      exportImage: true
    };
  },

  methods: {
    ...mapActions('product'),

    removeProduct(product) {
      if (!product) {
        this.selectedProducts = [];
        this.$parent.removeProduct();
        sessionStorage.removeItem(this.current_user_root.email);
        return;
      }
      var productSelected = this.selectedProducts.find( (e) => e.id == product.id);
      this.$parent.removeProduct(productSelected);
    },

    inventory(product) {
      let icon = '－';
      let text = '表示対象外';
      let iconText = '<span style="font-size: 14px;height: 25px;position: relative;top: 1px;">';

      if (!product.show_inventory || product.is_limit_inventory) {
        return `${iconText}${icon}</span>${text}`;
      };

      if (product.inventory > 0) {
        icon = '〇';
        text = '在庫有り(担当にご確認ください)​';
        return `${iconText}${icon}</span>${text}`;
      };

      return `${iconText}×</span>在庫なし(担当にご確認ください)`;
    },

    showCart() {
      this.is_show_cart = !this.is_show_cart
      this.$parent.showCart()
    },

    warning(value) {
      this.exportMode = value
      this.$bvModal.show('bv-modal-example')
    },

    submitForm() {
      if (this.exportMode == 'estimation_all') {
        this.$refs.estimation_all_form[0].submit();
      } else {
        this.$refs.suggestion_letter_all_form[0].submit();
      }
      this.$bvModal.hide('bv-modal-example')
    },

    warningMessage() {
      if (this.exportMode == 'estimation_all') {
        return "※11個以上の商品が入っています。このまま見積書作成を進めますか？";
      }
      return "※11個以上の商品が入っています。このまま提案書作成を進めますか？";
    }
  }
};
</script>

<style lang="scss" scoped>
.cart {
  width: 104%;
  left: 0;
  z-index: 202;
  background: #dee2e6;
  transition: all .2s ease 0s,top .5s ease 0s;
  overflow: scroll;
  max-height: 303px;
}

.e-flame {
  background: #fafafa;
  padding: 15px;
  min-height: 60px;
  width: 100%;
  height: 12rem;
}

.e-flame .item-image {
  width: 97%;
  height: 65%;
}

.item-image img {
  max-height: 100%;
  object-fit: scale-down;
}

.e-flame p {
  float: left;
  width: 100%;
  text-align: left;
  word-wrap: break-word;
  font-size: 10px;
  margin: 0px;
  max-height: 45px;
}

.cancel-product {
  position: absolute;
  right: -10px;
  top: 3px;
}

.cancel-all-product {
  position: sticky;
  top: 15px;
  font-size: 20px;
  height: 10px
}

.p-10 {
  padding: 5px;
  position: sticky;
  top: 0px;
  padding-left: 30px;
}

.p-10-sticky {
  position: sticky;
  top: 0px;
  z-index:  1;
  background-color: #dee2e6;
  height: 35px;
  width: 100%;
}

.m-l-10 {
  margin-left: 10px
}

.sticky-position {
  position: sticky;
  top: 20px;
  height: 100%;
}

.inventory {
  font-size: 10px !important;
  color: red;
}
.show-cart-btn {
  z-index: 202;
  width: 10%;
  color: #fff;
  border-color: #8c8c8c;
  background: #8c8c8c;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  letter-spacing: .06em;
  text-indent: 0.06rem;
  padding: 24px 0;
  margin: 20px auto 0;
  border: 1px solid #828282;
  border-radius: 4px;
  box-sizing: border-box;
  float: right;
}
.cart-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 203;
}

.modal-btn {
  width: 20%;
}

</style>