<template>
  <div class="c-search" >
    <div class="c-search__head" v-if="manager">
      <dl class="c-search__name">
        <dt>担当：</dt>
        <dd>{{manager.name}}</dd>
      </dl>
      <a class="c-search__mask" data-turbolinks="false" v-b-modal.modal-info-manager>
        <img :src="manager.avatar_url" alt="" v-if="manager.avatar_url">
        <img src="/img/no-image.png" alt="" v-else>
      </a>
      <a :href="`mailto:${manager.email}?subject=営業スタッフへのご連絡&body=${encodedBody()}`" class="c-search__link">
        <img src="/img/ico-mail.svg" alt="レターアイコン" width="" height="" class="c-search__mail">
      </a>
    </div>
    <div class="c-search__body">
      <div class="c-search__inner">
        <div class="c-search__btn">
          <p class="c-search__txt">検索</p>
          <img src="/img/ico-search.svg" alt="検索アイコン" width="" height="" class="c-search__ico">
        </div>
        <form role="search" method="get" class="" :action="`${customerRootUrl}/products/search`">
          <input type="hidden" name="view_as" :value="viewAsCustomer" v-if="viewAsCustomer"/>
          <input type="hidden" name="search_type" value="1"/>
          <input type="search" class="c-search__input" placeholder="商品名、キーワード、型番" v-model="keyword">
          <input type="hidden" name="keyword" v-model="textSearch">
          <button type="submit" value="検索" class="c-search__submit"></button>
        </form>
      </div>
      <a href=""><img src="/img/ico-search-side-on.svg" alt="閉じるアイコン" width="" height="" class="c-search__close"></a>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import Util from '@/utils/util.js';
import { mapActions, mapState } from 'vuex';
import {
  toFullwidthKana
} from 'japanese-string-utils';
export default {
  props: ['internal'],
  data() {
    return {
      customerRootUrl: this.internal ? process.env.VUE_APP_INTERNAL_URL : process.env.VUE_APP_CUSTOMER_URL,
      csrfToken: Util.getCsrfToken(),
      viewAsCustomer: Util.viewAsCustomer(),
      keyword: '',
      textSearch: ''
    };
  },
  directives: {
    ClickOutside
  },

  created() {
    if (!this.internal) {
      this.getManager();
    }
  },

  watch: {
    keyword() {
      this.textSearch = toFullwidthKana(this.keyword);
    }
  },

  computed: {
    ...mapState('user', {
      manager: state => state.manager,
      currentUser: state => state.currentUser
    })
  },

  methods: {
    ...mapActions('user', ['getManager']),

    close() {
      $('.c-search__input').css('display', 'none');
      $('.c-search__submit').removeClass('is-active');
    },

    encodedBody() {
      return encodeURIComponent(`( ) お問い合わせ
( ) ご意見
( ) ご連絡事項
お問い合わせ内容は以下にご記入ください。
－－－－－－－－－－－－－－－－－－－


－－－－－－－－－－－－－－－－－－－
＜お客様情報＞
メールアドレス：${this.currentUser.email}
会社名：${this.currentUser.company_name}
氏名：${this.currentUser.name}
`
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.c-search__mask {
  img {
    height: 58px;
  }
}
</style>