<template>
  <!-- HEADER -->
  <header id="header" class="l-header">
    <div class="c-maintenancebar" v-if="maintenance">
      <div class="c-maintenancebar__inner">
        <p>メンテナンス中です</p>
      </div>
    </div>
    <div class="c-modebar" v-if="(viewAsCustomer || internal) && current_user_root">
      <div class="c-modebar__inner">
        <p v-if="internal">現在は社内確認モードです</p>
        <p v-else>メニュー一覧</p>
        <a  :href="userRoot"><i></i>モード変更</a>
      </div>
    </div>
    <div class="l-header__inner">
      <h1 class="l-header__logo flex-container">
        <a :href="`${customerRootUrl}`| customerUrl()" data-turbolinks="false"><img src="/img/logo.svg" alt="株式会社デンソーソリューション" width="" height="" data-turbolinks="false"></a>
        <!-- <a v-if="(!internal && currentUser && !viewAsCustomer)" :href="`/customer/web_proposals`" data-turbolinks="false"><img src="/img/logo-web-densosolution.png" alt="" width="" height="" data-turbolinks="false"></a> -->
      </h1>
      <nav class="c-gnav">
        <ul class="c-gnav__list">
          <li class="c-gnav__item"><a :href="`${customerRootUrl}/banners` | customerUrl()" :class="isActive('banners') ? 'is-active' : null" data-turbolinks="false">特集一覧</a></li>
          <li class="c-gnav__item"><a :href="`${customerRootUrl}/products` | customerUrl()" :class="isActive('products') ? 'is-active' : null" data-turbolinks="false">商品一覧</a></li>
          <li class="c-gnav__item"><a :href="`${customerRootUrl}/news?internal=true`| customerUrl()" :class="isActive('news') ? 'is-active' : null" data-turbolinks="false">新着情報</a></li>
        </ul>
      </nav>
      <div class="c-header-meta customer-action" v-if="currentUser">
        <ul class="c-header-meta__list" v-if="viewAsCustomer || internal">
          <li class="c-header-meta__item">{{currentUser.company_name}}</li>
          <li class="c-header-meta__item"><span>{{currentUser.name}}</span>様</li>
        </ul>
        <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none text-dark text-left" no-caret v-else>
          <template #button-content>
            <ul class="c-header-meta__list">
              <li class="c-header-meta__item">{{currentUser.company_name}}</li>
              <li class="c-header-meta__item"><span>{{currentUser.name}}</span>様</li>
            </ul>
          </template>
          <b-dropdown-item :href="`${this.customerRootUrl}/sign_out`" data-method="delete" class="text-center" >ログアウト</b-dropdown-item>
        </b-dropdown>
        <a :href="`${customerRootUrl}/products/liked_products` | customerUrl()" class="c-header-meta__btn" data-turbolinks="false">
          <img src="/img/ico-heart-on.png" alt="" width="" height="" class="c-header-meta__ico is-on">
          <img src="/img/ico-heart-off.png" alt="" width="" height="" class="c-header-meta__ico is-off">
        </a>
      </div>
    </div>
    <modal-select-mode :internal="internal"></modal-select-mode>
  </header><!-- /HEADER -->
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Util from '@/utils/util.js';

export default {
  props: ['internal', 'maintenance', 'current_user_root'],
  data() {
    return {
      customerRootUrl: this.internal ? process.env.VUE_APP_INTERNAL_URL : process.env.VUE_APP_CUSTOMER_URL,
      userRoot: process.env.VUE_APP_USER_URL,
      viewAsCustomer: Util.viewAsCustomer(),
      webPPDomain: process.env.WEBPP_URL
    };
  },

  computed: {
    ...mapState('user', {
      currentUser: state => state.currentUser
    })
  },

  created() {
    if (!this.internal) {
      this.getCurrentUser();
    }
  },

  methods: {
    ...mapActions('user', ['getCurrentUser']),
    isActive(controllerName) {
      const pathname = window.location.pathname;
      return pathname.includes(controllerName);
    },

    selectMode() {
      $('#modalSelectMode').modal('show');
    }
  }
};
</script>

<style lang="scss" scoped>
  .is-active:after {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background: #dc0032;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: width .2s ease 0s
  }

  .flex-container {
    display: flex;
  }

  .flex-container > a {
    min-width: 254px;
    min-height: 40px;
    padding-right: 20px;
  }

  .flex-container > a > img{
    min-height: 40px;
  }

  ::v-deep {
    .customer-action {
      .dropdown-item {
        width: inherit!important;
      }
    }

    .dropdown-menu {
      padding: 0!important;
    }
  }
</style>