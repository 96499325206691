<template>
  <!-- TOPICS -->
  <section class="p-topics">
    <div class="p-topics__inner c-grid">
      <h2 class="p-topics__ttl c-title c-title--news">NEWS<span>新着情報</span></h2>
      <div class="p-topics__body">
        <ul class="p-topics__list">
          <template v-for="(news, index) in list_news">
            <li class="p-topics__item" :key="index">
              <a @click="openNewsDetail(news)">
                <time class="p-topics__time" datetime="2020-01-15">{{ news.posted_at | formatDate('YYYY年MM月DD日') }}</time>
                <div class="p-topics__post">
                  <ul class="c-meta-cat__list">
                    <li class="c-meta-cat__item"><span>{{ news.category_name }}</span></li>
                  </ul>
                  <h3 class="p-topics__dsc">{{ news.title }}</h3>
                </div>
              </a>
            </li>
          </template>
        </ul>
        <p class="p-topics__link"><a :href="`${customerRootUrl}/news?internal=false`| customerUrl()" data-turbolinks="false">もっと見る</a></p>
      </div>
    </div>
  </section><!-- /TOPICS -->
</template>

<script>
import Util from '@/utils/util.js';

export default {
  props: ['list_news', 'internal'],
  data() {
    return {
      customerRootUrl: this.internal ? process.env.VUE_APP_INTERNAL_URL : process.env.VUE_APP_CUSTOMER_URL
    };
  },
  methods: {
    openNewsDetail(news) {
      if (news.url) {
        window.location.href = news.url;
      } else {
        if (this.internal) {
          window.location.href = `${this.customerRootUrl}/news/${news.id}?internal=false`;
        } else {
          if (Util.viewAsCustomer()) {
            window.location.href = `${this.customerRootUrl}/news/${news.id}?view_as=${Util.viewAsCustomer()}`;
          } else {
            window.location.href = `${this.customerRootUrl}/news/${news.id}`;
          }
        }
      }
    }
  }
};
</script>

<style>

</style>