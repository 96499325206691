<template>
  <div>
    <common-header :internal="internal" :maintenance="maintenance" :current_user_root="current_user_root"></common-header>
    <common-search-box :internal="internal"></common-search-box>
    <main id="main" class="l-main">
      <!-- BREADCRUMB -->
      <div class="c-breadcrumb">
        <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="c-breadcrumb__list c-grid">
          <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="c-breadcrumb__item">
            <a itemprop="item" :href="`${customerUrl}`| customerUrl()" class="c-breadcrumb__link" data-turbolinks="false">
              <span itemprop="name"><i class="ico ico-home"></i>トップ</span>
            </a>
            <meta itemprop="position" content="1">
          </li>
          <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="c-breadcrumb__item">
            <a itemprop="item" href="" data-turbolinks="false">
              <span itemprop="name">商品一覧</span>
            </a>
            <meta itemprop="position" content="2">
          </li>
        </ol>
      </div><!-- /BREADCRUMB -->
      <!-- HEADLINE -->
      <div class="c-headline">
        <div class="c-headline__inner c-grid">
          <div class="c-headline__body">
            <h2 class="c-headline__ttl" v-if="keyword">“{{keyword}}”の検索結果一覧</h2>
            <h2 class="c-headline__ttl" v-else>商品一覧</h2>
            <p class="c-headline__dsc"><span>{{totalRows}}</span>件</p>
          </div>
        </div>
      </div><!-- /HEADLINE -->
      <!-- PRODUCT-ARCHIVE -->
      <section class="p-product-archive">
        <div class="p-product-archive__inner c-grid">
          <div class="p-product-archive__body">
            <div class="p-change-result">
              <div class="p-change-result__body">
                <div class="p-change-result__col">
                  <div class="c-switch">
                    <p class="c-switch__name">関連商品や補給品を</p>
                    <div class="c-switch__button">
                      <button :class="searchType == 0 ? 'is-current': ''" @click="changeSearchType(0)">表示しない</button>
                      <button :class="searchType == 1 ? 'is-current': ''" @click="changeSearchType(1)">表示する</button>
                    </div>
                  </div>
                </div>
                <div class="p-change-result__col row">
                  <div class="c-select">
                    <p class="c-select__name">並び替え：</p>
                    <div class="c-select-custom" v-if="rerender">
                      <select name="" id="" v-model="orderBy" @change="changeOrderBy()">
                        <!-- <option v-if="internal" value='page_view'>閲覧数ランキング</option>
                        <option v-if="internal" value='price_desc'>価格順（高い）</option>
                        <option v-if="internal" value='price_asc'>価格順（低い）</option> -->
                        <option value='name'>商品名順</option>
                        <option value='created_at_desc'>登録順（新しい）</option>
                      </select>
                    </div>
                  </div>
                  <button class="checkbox-off" @click="checkProduct()">一括選択</button>
                  <!-- <button class="checkbox-off" @click="removeProduct()">一括削除</button> -->
                </div>
              </div>
            </div>
            <div class="p-product-archive__row">
              <ul class="p-product-archive__list">
                <!-- <li class="p-product-archive__item show-check" v-if="internal" v-for="(product, index) in productList" :key="index"> -->
                <li class="p-product-archive__item show-check" v-if="internal" v-for="(product, index) in productList" :key="index" @mouseover="hidenProduct(product, false)" @mouseout="hidenProduct(product, true)">
                  <img :src="`/img/${index + 1}.png`" class="ranking" v-if="orderBy == 'page_view'">
                  <div class="form-check">
                    <input class="form-check-input product-check"
                      type="checkbox"
                      ref="reference"
                      :id="product.id"
                      :checked="isProductChecked(product)"
                      @change="checkProduct(product)"
                      :hidden="!isProductChecked(product)"
                    >
                    <!-- @mouseover="hidenProduct(product, false)" -->
                  </div>
                  <a :href="customerUrl + '/products/' + product.id  | customerUrl(view_as)" data-turbolinks="false">
                    <div class="c-flame">
                      <img :src="product.main_image_url" alt="" width="330" height="330" v-if="product.main_image_url">
                      <img src="/img/no-image.png" alt="" width="330" height="330" v-else>
                    </div>
                    <h3 class="p-product-archive__dsc">{{product.name}}</h3>
                    <p class="p-product-archive__num">{{product.product_model}}</p>
                    <p v-if="orderBy == 'price_desc' || orderBy == 'price_asc' " class="p-product-archive__num product-price">{{product.open_price}}</p>
                  </a>
                </li>

                <li class="p-product-archive__item" v-if="!internal" v-for="(product, index) in productList" :key="index">
                  <a :href="customerUrl + '/products/' + product.id  | customerUrl(view_as)" data-turbolinks="false">
                    <div class="c-flame">
                      <img :src="product.main_image_url" alt="" width="330" height="330" v-if="product.main_image_url">
                      <img src="/img/no-image.png" alt="" width="330" height="330" v-else>
                    </div>
                    <h3 class="p-product-archive__dsc">{{product.name}}</h3>
                    <p class="p-product-archive__num">{{product.product_model}}</p>
                  </a>
                </li>
              </ul>
            </div>
            <!-- PAGINATION -->
            <nav class="c-pagination" v-if="totalRows && totalRows/18 > 1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="18"
                first-number
                last-number
                class="c-pagination__list"
                prev-class="c-pagination__item is-prev"
                next-class="c-pagination__item is-next"
                page-class="c-pagination__item"
                @change="changePage"
              ></b-pagination>
            </nav>
            <!-- PAGINATION -->
          </div>
        </div>
      </section><!-- /PRODUCT-ARCHIVE -->
    </main><!-- /MAIN -->
    <!-- <div class="cart row" v-if="selectedProducts.length">
      <ul class="p-product-archive__list col-9 row  m-l-10">
        <div class="col-10 p-10-sticky">
          <p class="p-10">提案書生成する製品一覧<b style="color: red;">{{selectedProducts.length}}</b>件</p>
        </div>
        <li class="p-product-archive__item col-2" v-for="(product, index) in selectedProducts" :key="index">
          <button @click="removeProduct(product)" class="cancel-product">X</button>
          <div class="e-flame">
            <img :src="product.main_image_url" alt="" width="330" height="330" v-if="product.main_image_url">
            <img src="/img/no-image.png" alt="" width="330" height="330" v-else>
            <p>{{product.code}}</p>
            <p class="inventory"
              v-html="inventory(product)"
              v-if="!(!internal && !product.show_public_inventory || !internal && !product.show_inventory)"></p>
          </div>
        </li>
      </ul>
      
      <div class="col-2 sticky-position">
        <ul class="export-class">
          <li class="c-sidenav__item">
            <form class=""
              action="/internal/products/export"
              v-for="(key, value) in export_modes"
              :key="key"
              >
              <input v-if="selectedProducts.length" type="hidden" name="id" :value="selectedProducts[0].id"/>
              <input type="hidden" name="mode" :value="value"/>
              <input type="hidden" name="is_from_cart" :value="true"/>
              <input type="hidden" name="export_product_ids" :value="selectedProducts.map(x => x.id)"/>
              <button type="submit" class="btn-export-excel">{{key}}</button>
            </form>
          </li>
        </ul>
      </div>
      <button @click="removeProduct()" class="cancel-all-product">X</button>
    </div> -->
    <cart v-if="internal"
      :key="cartKey"
      :products="this.products"
      :product_variants="product_variants"
      :selected_products="this.selectedProducts"
      :from_show_page="false"
      :is_show_cart="this.isShowCart"
      :current_user_root="current_user_root"
    ></cart>
    <common-info-manager></common-info-manager>
    <common-footer :internal="internal"></common-footer>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Util from '@/utils/util.js';

export default {
  props: [
    'products',
    'total_rows_default',
    'keyword_default',
    'view_as',
    'internal',
    'current_page',
    'maintenance',
    'current_user_root',
    'product_variants'
  ],

  data() {
    return {
      customerUrl: this.internal ? process.env.VUE_APP_INTERNAL_URL : process.env.VUE_APP_CUSTOMER_URL,
      currentPage: 1,
      productList: [],
      totalRows: this.total_rows_default,
      keyword: this.keyword_default,
      subCategoryId: null,
      mainCategoryId: null,
      manufacturerName: null,
      searchType: 0,
      orderBy: 'name',
      selectedProducts: this.loadSelectedProduct(),
      rerender: true,
      isShowCheckbox: true,
      isShowCart: false,
      cartKey: "cart_key",
    };
  },

  created() {
    this.rerender = false;
    this.productList = _.cloneDeep(this.products);
    this.currentPage = this.current_page || 1;
    this.subCategoryId = new URL(location.href).searchParams.get('sub_category_id');
    this.mainCategoryId = new URL(location.href).searchParams.get('main_category_id');
    this.manufacturerName = new URL(location.href).searchParams.get('manufacturer_name');
    this.orderBy = new URL(location.href).searchParams.get('order_by') || 'name';
    this.searchType = new URL(location.href).searchParams.get('search_type') || 0;
  },

  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.rerender = true;
      }, 100);
    });

    window.onpopstate = function(event) {
      location.reload();
    };
  },

  $refs:{
    reference: HTMLElement
  },

  beforeMount() {
    const plugin = document.createElement('script');
    plugin.setAttribute(
      'src',
      '/js/index.bundle.js'
    );
    plugin.async = true;
    document.body.appendChild(plugin);
  },

  methods: {
    ...mapActions('product', ['searchProducts']),

    async changePage(page) {
      Util.updateUrlWithQuery({
        search_type: this.searchType,
        order_by: this.orderBy,
        page: page
      });

      this.$store.dispatch('system/setLoading', true);

      const data = await this.searchProducts({ main_category_id: this.mainCategoryId, sub_category_id: this.subCategoryId, manufacturer_name: this.manufacturerName, keyword: this.mainCategoryId || this.subCategoryId ? '' : this.keyword, page: page, internal: this.internal, search_type: this.searchType, order_by: this.orderBy });

      if (data && data.data && data.data.products) {
        this.productList = _.cloneDeep(data.data.products);
        this.currentPage = data.data.current_page;
        this.totalRows = data.data.total_rows;
      }

      this.$store.dispatch('system/setLoading', false);
    },

    async changeSearchType(type) {
      this.searchType = type;
      await this.changePage(1);
    },

    async changeOrderBy() {
      await this.changePage(1);
    },

    checkProduct(product) {
      if (!this.internal) {return};
      if (!product) {
        let currentProducts = [...this.productList]
        var unSelected = currentProducts.filter((e)=> !this.selectedProducts.map(e=>e.id).includes(e.id));
        if (unSelected.length < 1) {return;}

        this.selectedProducts.push(...unSelected)
        this.$refs.reference.map(e => e.hidden = false)
        this.productList.map((product) => product.checked = true)
        this.selectedProducts.map((product) => product.checked = true)
        this.isShowCart = true
        sessionStorage.setItem(this.current_user_root.email, JSON.stringify(this.selectedProducts.map(product => product)));
        this.cartKey = "checkAll";
        return;
      }
      if (product.checked || this.isProductChecked(product)) {
        this.removeProduct(product);
        return;
      }
      this.selectedProducts.push(product);
      product.checked = true
      this.isShowCart = true
      sessionStorage.setItem(this.current_user_root.email, JSON.stringify(this.selectedProducts.map(product => product)));
      this.cartKey = "checkProduct";
    },

    removeProduct(product) {
      if (!product) {
        this.selectedProducts = [];
        this.productList.map((product) => product.checked = false)
        this.$refs.reference.map(e => e.hidden = true)
        this.isShowCart = false
        sessionStorage.removeItem(this.current_user_root.email);
        this.cartKey = "removeAll";
        return;
      }

      var productSelected = this.selectedProducts.find( (e) => e.id == product.id);
      if (!productSelected) {return;}
      var index = this.selectedProducts.indexOf(productSelected);
      this.selectedProducts.splice(index, 1);
      productSelected.checked = false;
      product.checked = false;
      this.isShowCart = true
      this.changeProduct(this.productList, product.id, false)
      this.changeProduct(this.$refs.reference, product.id, false)
      sessionStorage.setItem(this.current_user_root.email, JSON.stringify(this.selectedProducts));
      this.cartKey = "removeProduct";
    },

    isProductChecked(product){
      if (this.$refs.reference) {
        var element = this.$refs.reference.find( (e) => e.id == product.id );
        if (element) {
          element.checked = product.checked
        }
      }
      if (this.selectedProducts.length) {
        var productSelected = this.selectedProducts.find( (e) => e.id == product.id )
        if (productSelected) {
          return productSelected.checked
        }
      }

      return product.checked
    },

    hidenProduct(product, value){
      if (product.checked) {value = false;}
      if (this.$refs.reference) {
        var element = this.$refs.reference.find( (e) => e.id == product.id );
        if (element) {
          element.hidden = value
        }
      }
    },

    inventory(product) {
      let icon = '－';
      let text = '表示対象外';
      let iconText = '<span style="font-size: 14px;height: 25px;position: relative;top: 1px;">';

      if (!product.show_inventory || product.is_limit_inventory) {
        return `${iconText}${icon}</span>${text}`;
      };

      if (product.inventory > 0) {
        icon = '〇';
        text = '在庫有り(担当にご確認ください)​';
        return `${iconText}${icon}</span>${text}`;
      };

      return `${iconText}×</span>在庫なし(担当にご確認ください)`;
    },

    checkAll() {
      this.$refs.reference.map( (e) => {
        e.hidden = this.isShowCheckbox
      } );
      this.isShowCheckbox = !this.isShowCheckbox
    },

    loadSelectedProduct() {
      if (!this.internal) {return;}

      let selected = JSON.parse(sessionStorage.getItem(this.current_user_root.email)) || [];
      // let variants = this.product_variants;
      // if ( variants ) {
      //   selected = selected.concat(variants.filter( e => !selected.map(object => object.id).includes(e.id)))
      //   sessionStorage.setItem(this.current_user_root.email, JSON.stringify(selected))
      // }
      this.products.filter(e => selected.map(object => object.id).includes(e.id)).map( e => e.checked = true );

      return selected
    },

    changeProduct(list, id, value) {
      var product = list.find(e => e.id == id)
      product.checked = value;
      product.hidden = !value;
    },

    showCart() {
      this.isShowCart = !this.isShowCart
    },

    removeProductOnIndex() {
      this.productList.forEach((product) => this.removeProduct(product));
    },
  }
};
</script>

<style lang="scss" scoped>
.p-product-archive__row {
  min-height: 45vh;
}

input[type=checkbox] {
  transform: scale(1.75);
}

.product-check {
  z-index: 1;
  margin-left: 7.7rem;
  margin-top: 0.5rem
}

.cart {
  width: 102%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 202;
  background: #dee2e6;
  transition: all .2s ease 0s,top .5s ease 0s;
  overflow: scroll;
  height: 25%
}

.e-flame {
  background: #fafafa;
  padding: 15px;
  min-height: 60px;
  width: 100%;
}

.e-flame img {
  width: 25%;
  position: absolute;
  height: 70%;
  max-height: 60px
}

.e-flame p {
  float: right;
  width: 65%;
  text-align: left;
  word-wrap: break-word;
  font-size: 12px;
  margin: 0px;
}

.cancel-product {
  position: absolute;
  right: -6px;
  top: 7px;
}

.cancel-all-product {
  position: sticky;
  top: 15px;
  font-size: 20px;
  height: 10px
}

.p-10 {
  padding: 10px;
  position: sticky;
  top: 0px;
}

.p-10-sticky {
  position: sticky;
  top: 0px;
  z-index:  1;
  background-color: #dee2e6;
  height: 48px;
}

.m-r {
  margin-right: 70px
}

.m-l-10 {
  margin-left: 10px
}

.product-price {
  font-size: 20px;
  color: red;
}

.sticky-position {
  position: sticky;
  top: 26px;
  height: 100px;
}

.ranking {
  height: 30px;
  width: 30px;
  position: relative;
  top: 25px;
  z-index: 1;
}
.inventory {
  font-size: 10px !important;
  color: red;
}

.checkbox-off {
  padding: 5px;
  color: #fff;
  border-color: #8c8c8c;
  background: #8c8c8c;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  letter-spacing: .06em;
  text-indent: 0.06rem;
  margin-left: 10px;
  border: 1px solid #828282;
  border-radius: 4px;
  box-sizing: border-box;
  float: right;
}
@media only screen and (min-width: 1023px) and (max-width: 1100px)  {
  .p-product-archive__row {
    min-height: 60vh;
  }
}
</style>